import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  private baseURL = environment.cclRoot + '/drf/';

  constructor(private http: HttpClient) {}

  /**
   * Given a group's ID returns an Observable to all the group's Facebook
   * enabled sites.
   */
  getSitesEnabled(groupId: number) {
    return this.http.get<any[]>(
      `${this.baseURL}facebook/group/${groupId}/sites-enabled`
    );
  }
}
