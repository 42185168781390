export enum State {
  Done = 'done',
  Attaching = 'attaching',
  Updating = 'updating',
  Error = 'error',
}

export enum Status {
  Inactive = 'inactive',
  Trialing = 'trialing',
  Active = 'active',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
}

export interface Source {
  brand: string;
  last4: number;
  exp_month: number;
  exp_year: number;
}

export interface Subscription {
  trial_start: any;
  trial_end: any;
}

export interface Customer {
  customerId: string;

  status: Status;
  state: State;

  email: string;

  amount_due?: number;

  subscriptionId?: string;
  subscription?: Subscription;

  sourceId?: string;
  source?: Source;

  itemId?: string;

  couponId?: string;
  planId?: string;
}
