import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'niceTime',
})
export class NiceTimePipe implements PipeTransform {
  transform(value: string) {
    const date = new Date(value);
    const _value = date.getTime();

    const dif = Math.floor((Date.now() - _value) / 1000 / 86400);
    if (dif < 30) {
      return convertToNiceDate(value);
    } else {
      const datePipe = new DatePipe('en-US');
      value = datePipe.transform(value, 'MMM-dd-yyyy');
      return value;
    }
  }
}

function convertToNiceDate(time: string) {
  const date = new Date(time),
    diff = (new Date().getTime() - date.getTime()) / 1000,
    daydiff = Math.floor(diff / 86400);

  if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) return '';

  const datePipe = new DatePipe('en-US');
  return (
    (daydiff == 0 && datePipe.transform(time, 'HH:mm')) ||
    (daydiff == 1 && 'Yesterday') ||
    (daydiff < 7 && daydiff + ' days ago') ||
    (daydiff < 31 && Math.ceil(daydiff / 7) + ' week(s) ago')
  );
}
