// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cclRoot: 'https://platform-dev.clientchatlive.com',
  authSubdomain: 'https://login-dev.',
  authURL: 'https://login-dev.clientchatlive.com/#/sign-in',
  stripeToken: 'pk_test_euuNlzxS2VkQh62tVpZdj5SV',
  cclStripeToken: 'pk_test_3s0qE59JhsHtFNwJocWFW3t5',
  firebaseConfig: {
    apiKey: 'AIzaSyAUg93UAy6OS4gmT4YjrtA5io7ukMgPy0w',
    authDomain: 'ccl-app-dev.firebaseapp.com',
    databaseURL: 'https://ccl-app-dev.firebaseio.com',
    projectId: 'ccl-app-dev',
    storageBucket: 'ccl-app-dev.appspot.com',
    messagingSenderId: '503091657796',
  },
  leadsEndpointUrl:
    'https://us-central1-ccl-app-dev.cloudfunctions.net/createLead',
  cloudFunctionsUrl: 'https://us-central1-ccl-app-dev.cloudfunctions.net',
  hubspotTrackingId: '6515396',
};

// export const environment = {
//   production: true,
//   cclRoot: "https://platform.clientchatlive.com",
//  authSubdomain: "https://login.",
//  // authURL: "https://login.clientchatlive.com/#/sign-in",
//   stripeToken: "pk_live_i6Xq1t5ALaLdV522q9m5GaDX",
//   cclStripeToken: "pk_live_IHoT2J9hSrWaxANUd1mHHWj1",
//   firebaseConfig: {
//     apiKey: "AIzaSyAqscEfcD0FCbBQjGG52Us7Pz0WNGroTM0",
//     authDomain: "client-chat-live.firebaseapp.com",
//     databaseURL: "https://client-chat-live.firebaseio.com",
//     projectId: "client-chat-live",
//     storageBucket: "client-chat-live.appspot.com",
//     messagingSenderId: "1048853359935",
//   },
//   leadsEndpointUrl:
//     "https://us-central1-client-chat-live.cloudfunctions.net/createLead",
//       cloudFunctionsUrl: "https://us-central1-client-chat-live.cloudfunctions.net",
// };

// export const environment = {
//   production: true,
//   cclRoot: 'https://platform.clientchatlive.com',
//   authURL: 'https://login.clientchatlive.com/#/sign-in',
//   stripeToken: 'pk_live_i6Xq1t5ALaLdV522q9m5GaDX',
//   cclStripeToken: 'pk_live_IHoT2J9hSrWaxANUd1mHHWj1',
//   firebaseConfig: {
//     apiKey: 'AIzaSyAqscEfcD0FCbBQjGG52Us7Pz0WNGroTM0',
//     authDomain: 'client-chat-live.firebaseapp.com',
//     databaseURL: 'https://client-chat-live.firebaseio.com',
//     projectId: 'client-chat-live',
//     storageBucket: 'client-chat-live.appspot.com',
//     messagingSenderId: '1048853359935'
//   },
//   leadsEndpointUrl:
//     'https://us-central1-client-chat-live.cloudfunctions.net/createLead'
// };
