import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

/**
 * This component is used to atomize the implementation of the MatCheckbox
 * indeterminate state, comonly used with the 'Select All' checkbox. It is
 * exactly the same as SelectAllCheckboxComponent but this one doesn't
 * require a FormControl, was designed to be used with two-way data binding
 * for the values.
 */
@Component({
  selector: 'app-select-all-checkbox-without-control',
  template: `
    <mat-checkbox
      color="primary"
      class="mat-option"
      [disableRipple]="true"
      [indeterminate]="isIndeterminate()"
      [checked]="isChecked()"
      (click)="toggleCheckbox($event)"
    >
      {{ text }}
    </mat-checkbox>
  `,
  styles: [''],
})
export class SelectAllCheckboxWithoutControlComponent implements OnInit {
  @Input() currentValue = [];
  @Input() values = [];
  @Input() text = 'Select All';
  @Input() valuesMap: (any) => any = (val) => val;
  @Input() toggleOnInit = false;
  @Output() currentValueChange = new EventEmitter<any[]>();
  @ViewChild(MatCheckbox, { static: true }) checkbox: MatCheckbox;

  constructor() {}

  ngOnInit() {}

  /**
   * Flag reflecting the checked state of the checkbox, it will be true if all
   * the items (checkboxes) were selected.
   */
  isChecked(): boolean {
    return (
      this.currentValue &&
      this.values.length &&
      this.currentValue.length === this.values.length
    );
  }

  /**
   * Flag reflecting the indeterminate state of the checkbox, it will be true if
   * not all the values were selected or not all the component properties were
   * provided.
   */
  isIndeterminate(): boolean {
    return (
      this.currentValue &&
      this.values.length &&
      this.currentValue.length &&
      this.currentValue.length < this.values.length
    );
  }

  /**
   * Custom handler of the checkbox click event.
   */
  toggleCheckbox(event?: any) {
    // stop event; checkbox will be toggled manually to be synchronous
    if (event) {
      event.preventDefault();
    }
    this.checkbox.toggle();

    // set control value dependending on new checkbox value
    if (this.checkbox.checked) {
      if (this.valuesMap) {
        // use valuesMap function to pluck value
        this.currentValueChange.emit(this.values.map(this.valuesMap));
      } else {
        this.currentValueChange.emit(this.values);
      }
    } else {
      this.currentValueChange.emit([]);
    }
  }
}
