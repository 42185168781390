import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { take, mergeMap, tap } from 'rxjs/operators';
import firebase from 'firebase';

import {
  SupportTicket,
  InformationType,
  InformationRequestSupportTicket,
} from './support-ticket';
import { AuthService } from './auth.service';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { UserService } from './user.service';
import { LiveChatService } from '../live-chat-leads/shared/live-chat.service';
import { Notify } from '../shared/services/notify.service';
import { SUPPORT_TICKET_TYPE } from './enum/SupportTicketType';

@Injectable()
export class SupportTicketService {
  collectionName = 'support-tickets';
  col: AngularFirestoreCollection<SupportTicket>;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public notify: Notify,
    private afs: AngularFirestore,
    private authService: AuthService,
    private lcService: LiveChatService
  ) {
    this.col = this.afs.collection<SupportTicket>(this.collectionName);
  }

  getDoc(supportTicketId: string): AngularFirestoreDocument<SupportTicket> {
    return this.col.doc<SupportTicket>(supportTicketId);
  }

  create<T extends SupportTicket>(supportTicket: T): Observable<void> {
    const id = this.afs.createId();
    return this.authService.uid$.pipe(
      take(1),
      mergeMap((uid) => {
        supportTicket.datetimeSent =
          firebase.firestore.FieldValue.serverTimestamp();
        supportTicket.user = uid;
        return this.update(id, supportTicket);
      })
    );
  }

  update<T extends SupportTicket>(
    id: string,
    supportTicket: T
  ): Observable<void> {
    return from(this.getDoc(id).set(supportTicket));
  }

  // Partially updates a support ticket
  partialUpdate(
    id: string,
    supportTicket: Partial<SupportTicket>
  ): Observable<void> {
    return from(this.getDoc(id).update(supportTicket));
  }

  // Deletes the given support ticket
  delete(id: string) {
    return from(this.getDoc(id).delete());
  }

  openInformationRequestDialog(
    informationType: InformationType
  ): Observable<boolean> {
    // ask if they want more information
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        content:
          'Sorry, but it looks like you don’t have access to this service.<br/>' +
          'Would you like to get more information from us?',
        buttons: [
          {
            text: 'No',
            value: false,
          },
          {
            text: 'Yes',
            value: true,
            color: 'primary',
          },
        ],
      },
    });

    return dialogRef.afterClosed().pipe(
      tap((confirmed) => {
        if (confirmed) {
          this.userService.user$.subscribe((user) => {
            // create a new ticket to generate an email
            const newTicket: InformationRequestSupportTicket = {
              status: 'open',
              type: SUPPORT_TICKET_TYPE.INFORMATION_REQUEST,
              informationType: informationType,
              userName: user.firstName + ' ' + user.lastName,
            };
            if (informationType == InformationType.LiveChat) {
              this.create({
                ...newTicket,
              }).subscribe(() =>
                this.notify.success('Request submitted successfully')
              );
            } else {
              // if is a request for reviews, then the ticket can have the information of the group
              this._createTicketForReviewsInformation(newTicket);
            }
          });
        }
      })
    );
  }

  private _createTicketForReviewsInformation(
    newTicket: InformationRequestSupportTicket
  ) {
    this.lcService.groups$.subscribe((groups) => {
      const group = groups[0];
      this.create({
        ...newTicket,
        groupName: group.name,
        groupId: group.id,
      }).subscribe(() => this.notify.success('Request submitted successfully'));
    });
  }
}
