import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  ValidatorFn,
  Validator,
  NG_VALIDATORS,
} from '@angular/forms';
import * as moment from 'moment';

/**
 * Validator that checks that an input's time must be greater than the given
 * time string.
 * Uses moment library to compare.
 */
export function minTimeValidator(minTime: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || !minTime) {
      return null;
    }
    const format = 'HH:mm';
    const isAfter = moment(control.value, format).isSameOrAfter(
      moment(minTime, format),
      'minute'
    );
    return isAfter ? null : { minTime: { value: control.value } };
  };
}

/**
 * Custom directive to validate that a time is greater than a stablished min
 * time.
 */
@Directive({
  selector: '[appMinTime]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinTimeDirective, multi: true },
  ],
})
export class MinTimeDirective implements Validator {
  @Input() appMinTime: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.appMinTime ? minTimeValidator(this.appMinTime)(control) : null;
  }
}
