import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable, from, combineLatest, Subject } from 'rxjs';
import { map, mergeMap, mapTo, shareReplay, take } from 'rxjs/operators';

import { AccountService } from './account.service';

import { Location, IdLocation } from './location';

@Injectable()
export class LocationService {
  collectionName = 'locations';
  requestCollectionName = 'reviews';
  private locations$: Observable<IdLocation[]>;
  private activeLocations$: Observable<IdLocation[]>;
  newLocationId$: Subject<string> = new Subject();

  constructor(
    private accountService: AccountService,
    private afs: AngularFirestore
  ) {}

  get locations(): Observable<IdLocation[]> {
    if (!this.locations$) {
      this.locations$ = this.accountService.doc$.pipe(
        mergeMap((accountDoc) => {
          const locationCollection = accountDoc.collection<Location>(
            this.collectionName,
            (ref) => ref.orderBy('name')
          );
          return locationCollection.snapshotChanges().pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as Location;
                const id = a.payload.doc.id;
                return { id, ...data } as IdLocation;
              })
            )
          );
        }),
        shareReplay(1)
      );
    }
    return this.locations$;
  }

  getLocationsByAccountId(accountId: string): Observable<IdLocation[]> {
    const firestorePath = `accounts/${accountId}/locations`;
    const locationCollection = this.afs.collection<Location>(
      firestorePath,
      (ref) => ref.orderBy('name')
    );

    return locationCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Location;
          const id = a.payload.doc.id;
          return { id, ...data } as IdLocation;
        })
      )
    );
  }

  get activeLocations(): Observable<IdLocation[]> {
    if (!this.activeLocations$) {
      this.activeLocations$ = this.accountService.doc$.pipe(
        mergeMap((accountDoc) =>
          accountDoc
            .collection<Location>(this.collectionName, (ref) =>
              ref.where('isActive', '==', true).orderBy('name')
            )
            .snapshotChanges()
            .pipe(
              map((actions) =>
                actions.map((a) => {
                  const data = a.payload.doc.data() as Location;
                  const id = a.payload.doc.id;
                  return { id, ...data } as IdLocation;
                })
              )
            )
        ),
        shareReplay(1)
      );
    }
    return this.activeLocations$;
  }

  get firstLocationID(): Observable<string | null> {
    return this.activeLocations.pipe(
      map((res) => (res.length ? res[0].id : null))
    );
  }

  getDoc(locationId: string): Observable<AngularFirestoreDocument<Location>> {
    return this.accountService.doc$.pipe(
      map((accountDoc) =>
        accountDoc.collection<Location>(this.collectionName).doc(locationId)
      )
    );
  }

  getDocByAccountId(
    accountId: string,
    locationId: string
  ): Observable<AngularFirestoreDocument<Location>> {
    return this.accountService
      .getDocById$(accountId)
      .pipe(
        map((accountDoc) =>
          accountDoc.collection<Location>(this.collectionName).doc(locationId)
        )
      );
  }

  getLocation(locationId: string): Observable<Location> {
    return this.getDoc(locationId).pipe(mergeMap((doc) => doc.valueChanges()));
  }

  getLocationFromAnotherAccount(
    accountId: string,
    locationId: string
  ): Observable<Location> {
    return this.getDocByAccountId(accountId, locationId).pipe(
      mergeMap((doc) => doc.valueChanges())
    );
  }

  create(loc: Location): Observable<string> {
    const id = this.afs.createId();
    return this.getDoc(id).pipe(
      take(1),
      map((newLocationDoc) => from(newLocationDoc.set(loc))),
      mapTo(id)
    );
  }

  createByAccountId(accountId: string, loc: Location): Observable<string> {
    const id = this.afs.createId();
    console.log(`createByAccountId started`);
    console.log(
      `Creating location on firestore for account ${accountId}:`,
      loc
    );
    return this.getDocByAccountId(accountId, id).pipe(
      take(1),
      map((newLocationDoc) => from(newLocationDoc.set(loc))),
      mapTo(id)
    );
  }

  update(loc: IdLocation): Observable<void> {
    return this.getDoc(loc.id).pipe(
      mergeMap((ocationDoc) => from(ocationDoc.update(loc)))
    );
  }

  updateByAccountId(accountId: string, loc: IdLocation): Observable<void> {
    return this.getDocByAccountId(accountId, loc.id).pipe(
      mergeMap((locationDoc) => from(locationDoc.update(loc)))
    );
  }

  addUrl(locationId: string, site: string, url: string): Observable<boolean> {
    const updates = {};
    updates['sites.' + site] = url;
    return this.getDoc(locationId).pipe(
      map((locationDoc) => locationDoc.update(updates)),
      mapTo(true)
    );
  }

  setActiveState(id: string, isActive: boolean): Observable<void> {
    // Start a batch of writes
    const batch = this.afs.firestore.batch();
    // Get location requests and location doc
    return combineLatest(
      this.accountService.doc$.pipe(
        map((doc) =>
          doc.collection(this.requestCollectionName, (ref) =>
            ref.where('location', '==', id)
          )
        ),
        mergeMap((requestCol) => from(requestCol.ref.get()))
      ),
      this.getDoc(id)
    ).pipe(
      mergeMap(([requestQuery, locDoc]) => {
        batch.update(locDoc.ref, { isActive: isActive });
        requestQuery.docs.forEach((docSnap) => {
          batch.update(docSnap.ref, { isActive: isActive });
        });
        return from(batch.commit());
      })
    );
  }

  setActiveStateByAccountId(
    accountId: string,
    id: string,
    isActive: boolean
  ): Observable<void> {
    // Start a batch of writes
    const batch = this.afs.firestore.batch();
    // Get location requests and location doc
    return combineLatest(
      this.accountService.getDocById$(accountId).pipe(
        map((doc) =>
          doc.collection(this.requestCollectionName, (ref) =>
            ref.where('location', '==', id)
          )
        ),
        mergeMap((requestCol) => from(requestCol.ref.get()))
      ),
      this.getDocByAccountId(accountId, id)
    ).pipe(
      mergeMap(([requestQuery, locDoc]) => {
        batch.update(locDoc.ref, { isActive: isActive });
        requestQuery.docs.forEach((docSnap) => {
          batch.update(docSnap.ref, { isActive: isActive });
        });
        return from(batch.commit());
      })
    );
  }

  delete(id: string): Observable<void> {
    return this.getDoc(id).pipe(mergeMap((doc) => from(doc.delete())));
  }

  deleteByAccountId(accountId: string, id: string): Observable<void> {
    return this.getDocByAccountId(accountId, id).pipe(
      mergeMap((doc) => from(doc.delete()))
    );
  }
}
