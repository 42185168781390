import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { UUID } from 'angular2-uuid';
import { finalize } from 'rxjs/operators';
import { NewSiteTicket, NewSiteTicketProgress } from '../NewSiteTicketModel';

@Component({
  selector: 'app-new-site-ticket-special-request',
  templateUrl: './new-site-ticket-special-request.component.html',
  styleUrls: ['./new-site-ticket-special-request.component.scss'],
})
export class NewSiteTicketSpecialRequestComponent implements OnInit {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;
  @Output() changeCurrentStep = new EventEmitter<string>();
  isLoading = true;
  isCompleted = false;
  specialRequests: string = null;
  specialRequestAttachments = [];
  isLoadingImage = false;

  @ViewChild('fileUploader', { static: false }) fileUploader: ElementRef;

  constructor(private fsStorage: AngularFireStorage) {}

  ngOnInit() {
    this.specialRequests = this.model.specialRequests;
    this.specialRequestAttachments = this.model.specialRequestAttachments;
    this.isCompleted = this.progress.specialRequestCompleted === true;

    window.setTimeout(() => (this.isLoading = false), 500);
  }

  /**
   * Resets the image URL variable so the image is no longer asociated to the
   * special request.
   *
   * TODO: Also delete the file from Firebase Storage
   */
  onRemoveFile(index: number) {
    this.specialRequestAttachments.splice(index, 1);
  }

  /**
   * When a file is selected through the input this function verifies that the
   * selected file is an image before uploading to Firebase Storage.
   */
  onFilesChanged() {
    this.isLoadingImage = true;
    var file = this.fileUploader.nativeElement.files[0] as File;
    let fileExtension = '';
    const splittedName = file.name.split('.');
    if (splittedName.length > 0) {
      fileExtension = splittedName[splittedName.length - 1];
    }
    var mimeType = file.type;
    const isImage =
      mimeType.match(/image\/*/) != null || mimeType === 'application/pdf';
    if (isImage) {
      this.uploadImage(file, fileExtension);
    } else {
      alert('Only images and PDFs are supported');
    }
  }

  /**
   * Uploads the file to Firebase Storage and store its URL.
   */
  uploadImage(file: File, fileExtension: string) {
    const directory = 'requests/';
    const filePath = directory + UUID.UUID() + '.' + fileExtension;
    const fileRef = this.fsStorage.ref(filePath);

    const task = this.fsStorage.upload(filePath, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this.specialRequestAttachments.push({ url, fileExtension });
            this.isLoadingImage = false;
          });
        })
      )
      .subscribe();
  }

  /**
   * Flag indicating that the fields where ignored (are empty) so the user wants
   * to skip this step.
   */
  isSkip() {
    return !this.specialRequests && !this.specialRequestAttachments.length;
  }

  /**
   * When the form is submitted saves its values and continues to the next
   * step, if they are empty it continues anyway without storing anything.
   */
  onSubmit() {
    if (this.isSkip()) {
      this.onSkip();
      return;
    }
    this.isLoading = true;

    this.model.specialRequests = this.specialRequests || null;
    this.model.specialRequestAttachments = this.specialRequestAttachments;

    this.progress.specialRequestCompleted = true;
    this.changeCurrentStep.emit('submit');
  }

  /**
   * Since this step is optional this function continues to the next step without
   * saving any data.
   */
  onSkip() {
    this.progress.specialRequestCompleted = true;
    this.changeCurrentStep.emit('submit');
  }
}
