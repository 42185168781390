import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuid } from 'uuid';
import firebase from '@firebase/app';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { Notify } from 'src/app/shared/services/notify.service';

/**
 * This component was built to work as a MatDialog to create a new group's custom
 * sender (Check Firestore collection 'group-email-senders'). To do this it will need
 * a name and an email and it will generates a code that will be sent through email
 * and that will trigger the creation if the user confirms the code.
 *
 * TODO: Change the logic of the verification code to be handled on the backend.
 */
@Component({
  selector: 'app-group-custom-sender-editor',
  templateUrl: './group-custom-sender-editor.component.html',
  styleUrls: ['./group-custom-sender-editor.component.scss'],
})
export class GroupCustomSenderEditorComponent implements OnInit, OnDestroy {
  name: string;
  email: string;
  isLoading = true;
  emailSent = false;
  code: string;
  userCode: string;
  errorMessage = '';
  groupId: number;

  sub: Subscription;

  constructor(
    public cclGroupService: CclSelectedGroupService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GroupCustomSenderEditorComponent>,
    public afs: AngularFirestore,
    public notify: Notify
  ) {}

  /**
   * Get's the current group ID.
   */
  ngOnInit() {
    this.sub = this.cclGroupService.selectedId.subscribe(
      (groupId) => {
        this.groupId = groupId;
        this.isLoading = false;
      },
      (_) => {
        this.isLoading = false;
        this.errorMessage = "Request couldn't be sent. Please try again";
      }
    );
  }

  /**
   * Unsuscribe from the current's group subscription.
   */
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  /**
   * When the form is submitted creates a verification code and sends it to the
   * given email.
   */
  onSubmit() {
    this.errorMessage = '';
    if (this.emailSent) {
      return;
    }

    this.isLoading = true;
    this.code = uuid();
    const sendVerificationCodeForCustomEmailSender = firebase
      .functions()
      .httpsCallable('sendVerificationCodeForCustomEmailSender');
    sendVerificationCodeForCustomEmailSender({
      code: this.code,
      name: this.name,
      email: this.email,
    })
      .then(() => {
        this.emailSent = true;
        this.isLoading = false;
      })
      .catch((_) => {
        this.isLoading = false;
        this.errorMessage = "Request couldn't be sent. Please try again";
      });
  }

  /**
   * When the code sent to the given email is provided if it is valid it will create a
   * Firestore document with all the data of the component on 'group-email-senders'
   * collection.
   */
  async onConfirmCode() {
    this.errorMessage = '';
    if (!this.emailSent) {
      return;
    }

    if (this.code != this.userCode) {
      this.errorMessage = 'The verification code is wrong.';
      return;
    }

    this.isLoading = true;
    this.afs
      .collection('group-email-senders')
      .add({
        groupId: this.groupId,
        name: this.name,
        email: this.email,
      })
      .then((_) => {
        this.dialogRef.close(true);
        this.notify.success('Email verified correctly');
      })
      .catch((e) => {
        console.error('Error at saving custom email', e);
        this.isLoading = false;
        this.errorMessage = "Request couldn't be sent. Please try again";
      });
  }
}
