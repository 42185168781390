import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AlertDialogComponent } from '../../shared/alert-dialog/alert-dialog.component';
import { AuthService } from '../../core/auth.service';

/**
 * This component is used as a MatDialog to confirm the email/password
 * authentication method.
 */
@Component({
  selector: 'app-password-checker',
  templateUrl: './password-checker.component.html',
  styleUrls: ['./password-checker.component.css'],
})
export class PasswordCheckerComponent implements OnInit {
  email: string;
  password: string;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PasswordCheckerComponent>
  ) {}

  /**
   * Gets the current user's email.
   */
  ngOnInit() {
    this.authService.user$.subscribe((user) => (this.email = user.email));
  }

  /**
   * When the form is submitted it will used its values to validate if it is
   * possible to authenticate with them.
   */
  submitForm() {
    this.authService
      .checkPassword(this.email, this.password)
      .then((isCorrect) => {
        if (!isCorrect) {
          this.dialog.open(AlertDialogComponent, {
            data: {
              title: 'Unable to confirm password',
              content: 'Please try again.',
              buttons: [
                {
                  text: 'OK',
                  value: false,
                  color: 'primary',
                },
              ],
            },
          });
        } else {
          this.dialogRef.close(true);
        }
      });
  }
}
