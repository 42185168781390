import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * This component is used to setup the auto-response message that is going to be
 * used by the group. It also has a toggle to enable or disable the auto-responder.
 *
 * TODO: Make use of the group service in all the functions that are using
 * AngularFirestore service directly.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-auto-responder-editor',
  templateUrl: './auto-responder-editor.component.html',
  styleUrls: ['./auto-responder-editor.component.scss'],
})
export class AutoResponderEditorComponent implements OnInit, OnDestroy {
  groupId: number;
  autoResponse: string = '';
  autoResponseHoursActive: boolean;
  isLoading = true;

  constructor(
    private cclGroupService: CclSelectedGroupService,
    private afs: AngularFirestore,
    private snack: MatSnackBar
  ) {}

  /**
   * Gets the current group and the auto-response data from it.
   */
  ngOnInit() {
    this.cclGroupService.selectedId.subscribe((groupId) => {
      this.isLoading = true;
      this.groupId = groupId;
      if (groupId !== null) {
        this.onGroupChanged();
      }
    });
  }

  /**
   * Not implemented.
   */
  ngOnDestroy() {}

  /**
   * Gets the stored auto-response data from the current group.
   */
  onGroupChanged() {
    this.afs
      .doc(`ccl-groups/${this.groupId}`)
      .snapshotChanges()
      .subscribe((snap) => {
        const groupData = snap.payload.data() as any;
        if (groupData) {
          this.autoResponseHoursActive =
            groupData.autoResponseHoursActive === true;
          this.autoResponse = groupData.autoResponse;
        }
        this.isLoading = false;
      });
  }

  /**
   * Disables the auto-response hours active flag at Firestore document level.
   */
  onToggleAutoResponseHourStatus() {
    this.afs
      .doc(`ccl-groups/${this.groupId}`)
      .set(
        {
          autoResponseHoursActive: !this.autoResponseHoursActive,
        },
        { merge: true }
      )
      .then((_) => {});
  }

  /**
   * When the form is submitted it will store the auto-response message provided on
   * this component as a property of the group's Firestore document.
   */
  saveChanges() {
    this.isLoading = true;
    this.afs
      .doc(`ccl-groups/${this.groupId}`)
      .set(
        {
          autoResponse: this.autoResponse,
        },
        { merge: true }
      )
      .then((_) => {
        this.isLoading = false;
        this.snack.open('Auto response updated correctly', 'Ok');
      });
  }
}
