import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { UserService } from '../core/user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { take } from 'rxjs/operators';

/**
 * This component is used only to redirects to leads or reviews section of
 * the dashboard based in the flag if the user is Live Chat user.
 *
 * TODO: Make sure that this component is really being used and delete if not.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-user-redirection',
  templateUrl: './user-redirection.component.html',
  styleUrls: ['./user-redirection.component.scss'],
})
export class UserRedirectionComponent implements OnInit, OnDestroy {
  currentUserSub: Subscription;

  constructor(
    private userSevice: UserService,
    private router: Router,
    public ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.currentUserSub = this.userSevice.currentUserWithId$
      .pipe(take(1))
      .subscribe((user) => {
        if (user.isLiveChatUser === true) {
          this.router.navigateByUrl('/live-chat/leads/charts', {});
        } else {
          this.router.navigateByUrl('/reviews/reviews/posted', {});
        }
      });
  }

  ngOnDestroy(): void {}
}
