import { Injectable } from '@angular/core';
import { of, ReplaySubject, Observable } from 'rxjs';
import { flatMap, map, distinctUntilChanged } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { CclGroupsStoreService } from './ccl-groups-store.service';
import { UserService } from 'src/app/core/user.service';
import { CclGroupWithId, CclGroup } from 'src/app/core/ccl-group';
import { isCclGlobalAdmin } from 'src/app/core/user';
import { CclGroupFS } from 'src/app/firebase/dto/CclGroupFS';

export interface SelectedCclGroupResult {
  id: number;
  group: CclGroupFS;
}

/**
 * Holds the current ccl group selected in the dashboard.
 * Admin can select any group using a dropdown. Users can only get their assigned group in the Platform.
 */
@Injectable({
  providedIn: 'root',
})
export class CclSelectedGroupService {
  private selectedId$ = new ReplaySubject<number>(1);

  constructor(
    private afs: AngularFirestore,
    private userService: UserService,
    private cclGroupsStore: CclGroupsStoreService
  ) {
    this.userService.currentUserWithId$.subscribe((currentUser) => {
      let selectedGroupId = null;
      this.cclGroupsStore.groupsForUser$.subscribe((groups) => {
        if (isCclGlobalAdmin(currentUser)) {
          const storedGroupId = localStorage.getItem(
            currentUser.id + '-selectedGroupId'
          );
          if (storedGroupId) {
            selectedGroupId = Number(storedGroupId);
            if (selectedGroupId === 1) {
              selectedGroupId = null;
            }
          }
        }

        if (!selectedGroupId) {
          selectedGroupId = groups[0].id;
        }
        this.setSelectedId(selectedGroupId);
      });
    });
  }

  get selectedId() {
    return this.selectedId$.asObservable().pipe(distinctUntilChanged());
  }

  setSelectedId(id: number) {
    this.selectedId$.next(id);
  }

  /**
   * Get the selected CCL Group metadata. Returns the selected id, and the group model (null if does not exists)
   */
  get cclGroupWithMeta$(): Observable<SelectedCclGroupResult> {
    return this.selectedId.pipe(
      flatMap((groupId) => {
        if (!groupId) {
          return of(null);
        }
        return this.afs
          .doc<CclGroup>(`ccl-groups/${groupId}`)
          .snapshotChanges()
          .pipe(
            map((snap) => ({
              id: groupId,
              group: snap.payload.data(),
            }))
          );
      })
    );
  }

  /**
   * TODO: Refactor this function, since it's a bad implementation.
   * Get the selected CCL Group, with it's firebase UID. If no document exists, it returns
   * a model only with the ID.
   */
  sureGetCclGroupWithId(): Observable<CclGroupWithId> {
    return this.selectedId.pipe(
      flatMap((groupId) => {
        return this.afs
          .doc<CclGroup>(`ccl-groups/${groupId}`)
          .snapshotChanges()
          .pipe(
            map((snap) => {
              return {
                id: snap.payload.id,
                ...snap.payload.data(),
              } as CclGroupWithId;
            })
          );
      })
    );
  }
}
