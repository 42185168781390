import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TicketsService } from 'src/app/ccl-service-tickets/tickets.service';
import { IdUser } from 'src/app/core/user';
import { UserService } from 'src/app/core/user.service';
import { LiveChatService } from 'src/app/live-chat-leads/shared/live-chat.service';
import { TICKET_PRIORITY_VALUES } from 'src/app/ccl-service-tickets/enums/TicketPriority';
import { TICKET_CATEGORIES_VALUES } from 'src/app/ccl-service-tickets/enums/TicketCategories';

/**
 * This component was built to work as a MatDialog to request an integration with
 * Facebook. It onloy needs the ``businessName`` and internally it will use the
 * current user and group and create a support ticket to handle the integration.
 *
 * TODO: Type the support ticket.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-facebook-setup-modal',
  templateUrl: './facebook-setup-modal.component.html',
  styleUrls: ['./facebook-setup-modal.component.scss'],
})
export class FacebookSetupModalComponent implements OnInit, OnDestroy {
  groupId: number;
  groupIdSub: Subscription;
  businessName = '';
  selectedGroup: any;
  user: IdUser;
  isLoading = true;
  success = false;

  constructor(
    public dialogRef: MatDialogRef<FacebookSetupModalComponent>,
    private ticketsService: TicketsService,
    private lcService: LiveChatService,
    private groupService: CclSelectedGroupService,
    private userService: UserService
  ) {}

  ngOnDestroy(): void {}

  /**
   * Gets the current group and user.
   */
  ngOnInit(): void {
    this.groupIdSub = this.groupService.selectedId.subscribe((groupId) => {
      this.groupId = groupId;
      this.lcService.allGroups$.subscribe((groups) => {
        this.selectedGroup = groups.find((g) => g.id == groupId);
        this.isLoading = false;
      });
    });
    this.userService.currentUserWithId$.subscribe((user: IdUser) => {
      this.user = user;
    });
  }

  /**
   * When the form is submitted it creates an ``Integration`` ticket assigned to
   * ``Carlos`` with the provided ``business name``.
   */
  submitForm() {
    this.isLoading = true;
    const { name: groupName, client_uref } = this.selectedGroup;
    const { id, firstName, lastName, email } = this.user;
    const newSupportTicket = {
      subject: `Facebook Messenger Integration Request: ${groupName}`,
      description: `Firm or Business Name: ${this.businessName}`,
      attachments: [],
      sites: [],
      notificationTargets: [],
      priority: TICKET_PRIORITY_VALUES.MEDIUM,
      groupId: this.groupId,
      groupName,
      groupClientHref: client_uref,
      user: id,
      email,
      userName: `${firstName} ${lastName}`,
      assignee: '5b7206207cefaf05bcc36470', // Carlos
      category: [TICKET_CATEGORIES_VALUES.INTEGRATION],
    };
    this.createSupportTicket(newSupportTicket);
  }

  /**
   * Stores the given support ticket on its Firestore collection.
   */
  createSupportTicket(supportTicket) {
    this.ticketsService
      .createSupportTicket(supportTicket)
      .then((_) => {
        this.isLoading = false;
        this.success = true;
      })
      .catch((_) => (this.success = false));
  }
}
