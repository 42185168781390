import { Component, OnInit } from '@angular/core';
import { AlertDialogComponent } from 'src/app/shared/alert-dialog/alert-dialog.component';
import {
  AutoResponseDaySchedule,
  AutoResponseSchedule,
} from '../models/auto-responder-day-schedule-range';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { CclGroupsService } from 'src/app/firebase/firestore/ccl-groups/ccl-groups.service';
import { TIME_ZONES } from 'src/app/core/enum/TimeZone';

/**
 * This component is used to setup the auto-response hours that are going to be
 * used by the group. It also has a time-zone selector for these hours.
 */
@Component({
  selector: 'app-auto-responder-hours-editor',
  templateUrl: './auto-responder-hours-editor.component.html',
  styleUrls: ['./auto-responder-hours-editor.component.scss'],
})
export class BusinessHoursEditorComponent implements OnInit {
  groupId: number;
  autoResponseHoursActive: boolean;
  autoResponseHours: AutoResponseSchedule;
  isLoading = true;
  autoResponseDays: number[] = [1, 2, 3, 4, 5, 6, 0];
  availableTimeZones = TIME_ZONES;
  selectedTimeZone: string;

  constructor(
    private cclGroupService: CclSelectedGroupService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private readonly _cclGroupService: CclGroupsService
  ) {}

  /**
   * Gets the current group and the auto-response data from it.
   */
  ngOnInit() {
    this.cclGroupService.selectedId.subscribe((groupId) => {
      this.isLoading = true;
      this.groupId = groupId;
      if (groupId !== null) {
        this.onGroupChanged();
      }
    });
  }

  /**
   * Gets the stored auto-responder data from the current group.
   */
  onGroupChanged() {
    this._cclGroupService
      .getGroupServiceById(this.groupId)
      .subscribe((cclGroup) => {
        this.autoResponseHoursActive = !!cclGroup.autoResponseHoursActive;
        this.autoResponseHours = cclGroup.autoResponseHours || {};
        this.selectedTimeZone =
          cclGroup.autoResponseTimeZone || TIME_ZONES['US/Central'];
        this.isLoading = false;
      });
  }

  /**
   * Callback to open the 'copy settings to other days' dialog.
   */
  onCopyToBottomWeekDays(dayIndex: number) {
    this.showPromptCopyToBottomWeekDays(dayIndex);
  }

  /**
   * Shows an alert MatDialog to confirm that the user wants to copy the setup
   * of the given day to the rest below that day.
   */
  private showPromptCopyToBottomWeekDays(dayIndex: number) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        title: 'Copy schedule',
        content: 'Would you like to copy these settings to all Weekdays?',
        buttons: [
          {
            text: 'Cancel',
            value: false,
          },
          {
            text: 'Yes, copy',
            value: true,
            color: 'primary',
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.copyToBottomWeekDays(dayIndex);
      }
    });
  }

  /**
   * Copies the auto-responder times data from the given day to those below
   * it, e.g. if Tuesday (2) was provided it will copy information from Tuesday
   * to the rest of the days except Monday that is before Tuesday.
   */
  private copyToBottomWeekDays(dayIndex: number) {
    const dayIndexesToCopy = this.autoResponseDays.filter(
      (day) => day !== dayIndex
    );
    const sourceSchedule = this.autoResponseHours[dayIndex];
    const jsonSourceSchedule = JSON.stringify(sourceSchedule);
    dayIndexesToCopy.forEach((day) => {
      const clonedSchedule: AutoResponseDaySchedule[] =
        JSON.parse(jsonSourceSchedule);
      this.autoResponseHours[day] = clonedSchedule;
    });
  }

  /**
   * Saves the auto-responder hours data on the group's Firestore document.
   */
  saveAutoResponseHours() {
    this.isLoading = true;
    const autoResponseSetup = {
      autoResponseHours: this.autoResponseHours,
      autoResponseTimeZone: this.selectedTimeZone,
    };
    this._cclGroupService
      .updateCclGroup(this.groupId, autoResponseSetup)
      .then((_) => {
        this.isLoading = false;
        this.snack.open('Auto Response hours updated correctly', 'Ok');
      });
  }
}
