export enum TICKET_PRIORITY_VALUES {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum TICKET_PRIORITY_LABELS {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}
