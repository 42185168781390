import * as memoizee from 'memoizee';

/**
 * Performs memoization of a function.
 *
 * TODO: Types would be apreciated here.
 */
export function memoize() {
  return (_, __, descriptor) => {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction);
    descriptor.value = function () {
      return newFunction.apply(this, arguments);
    };
  };
}
