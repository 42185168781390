import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageFS } from 'src/app/firebase/dto/MessageFS';
import { MainFirestoreService } from '../../../main-firestore.service';
import firebase from 'firebase';
import { CHAT_MESSAGE_STATUSES } from 'src/app/core/enum/ChatMessage';

@Injectable({
  providedIn: 'root',
})
export class MessagesService extends MainFirestoreService<MessageFS> {
  groupsCollectionId = 'ccl-groups';
  conversationsCollectionId = 'conversations';
  messagesCollectionId = 'messages';

  constructor(protected readonly _firestore: AngularFirestore) {
    super(_firestore);
  }

  getMessagesFromConveration(groupId: number | string, conversationId: string) {
    return this.get(
      `${this.groupsCollectionId}/${groupId}/${this.conversationsCollectionId}/${conversationId}/${this.messagesCollectionId}`,
      (ref) => ref.orderBy('dateSend')
    );
  }

  saveMessage(
    groupId: number | string,
    conversationId: string,
    message: MessageFS
  ) {
    return this.create(
      `${this.groupsCollectionId}/${groupId}/${this.conversationsCollectionId}/${conversationId}/${this.messagesCollectionId}`,
      {
        dateSend: firebase.firestore.Timestamp.now(),
        isClient: false,
        status: CHAT_MESSAGE_STATUSES.PENDING,
        ...message,
      }
    );
  }
}
