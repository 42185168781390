import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewSiteTicket, NewSiteTicketProgress } from '../NewSiteTicketModel';

@Component({
  selector: 'app-new-site-ticket-notifications',
  templateUrl: './new-site-ticket-notifications.component.html',
  styleUrls: ['./new-site-ticket-notifications.component.scss'],
})
export class NewSiteTicketNotificationsComponent implements OnInit {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;
  @Output() changeCurrentStep = new EventEmitter<string>();

  emails: {
    email?: string;
    saved?: boolean;
    isPlaceHolder?: boolean;
    showButton?: boolean;
  }[] = [{ email: null, isPlaceHolder: true, showButton: false }];
  phoneNumbers: {
    number?: string;
    saved?: boolean;
    isPlaceHolder?: boolean;
    showButton?: boolean;
  }[] = [{ number: null, isPlaceHolder: true, showButton: false }];
  isLoading = true;
  isCompleted = false;
  unsaved = false;

  constructor() {}

  /**
   * Gets the number of emails with placeholder (valid emails).
   */
  get validEmailsCount() {
    return this.emails.filter((e) => !e.isPlaceHolder).length;
  }

  /**
   * Checks that there is emails with placeholder or creates an empty placeholder.
   */
  searchForPlaceholder() {
    const placeHolder = this.emails.find((x) => x.isPlaceHolder);
    if (!placeHolder) {
      this.emails.push({
        email: null,
        saved: false,
        isPlaceHolder: true,
        showButton: true,
      });
    }
  }

  /**
   * Checks that there is phone numbers with placeholder or creates an empty
   * placeholder.
   */
  searchForPlaceholderPhoneNumber() {
    const placeHolder = this.phoneNumbers.find((x) => x.isPlaceHolder);
    if (!placeHolder) {
      this.phoneNumbers.push({
        number: null,
        saved: false,
        isPlaceHolder: true,
        showButton: true,
      });
    }
  }

  /**
   * Initialize the variables used in this component from input data and creates
   * empty placholders for both emails and phone numbers.
   */
  ngOnInit() {
    if (this.model.notificationEmails) {
      this.emails = this.model.notificationEmails.map((x) => {
        return { email: x, saved: true, isPlaceHolder: false };
      });
      this.emails.push({
        email: null,
        isPlaceHolder: true,
        showButton: true,
      });
    }
    if (this.model.notificationPhoneNumbers) {
      this.phoneNumbers = this.model.notificationPhoneNumbers.map((x) => {
        return { number: x, saved: true };
      });
      this.phoneNumbers.push({
        number: null,
        isPlaceHolder: true,
        showButton: true,
      });
    }
    window.setTimeout(() => (this.isLoading = false), 500);
  }

  /**
   * Flag indicating wheter the given email is a valid email using a regular
   * expression.
   */
  isEmail(email: string) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * Flag indicating wheter the given array exists or not.
   */
  checkIfDuplicateExists(w: string[]): boolean {
    return new Set(w).size !== w.length;
  }

  /**
   * Flag indicating wheter the emails AND the phone numbers provided are valid
   * e.g. each has placeholder, is valid and is not duplicated.
   */
  isValid() {
    if (!this.emails) return false;
    const emailsWithoutPlaceHolder = this.emails.filter(
      (x) => !x.isPlaceHolder
    );
    if (emailsWithoutPlaceHolder.length < 1) return false;
    let invalidEmail = emailsWithoutPlaceHolder.find(
      (email) => !this.isEmail(email.email)
    );

    let emailListValid = !invalidEmail;

    if (emailListValid) {
      // check for duplicated emails
      const emailReduced = emailsWithoutPlaceHolder.map((x) => x.email);
      emailListValid = !this.checkIfDuplicateExists(emailReduced);
    }

    if (!this.phoneNumbers) return false;
    const phoneNumbersWithoutPlaceHolder = this.phoneNumbers.filter(
      (x) => !x.isPlaceHolder
    );
    const invalidPhoneNumber = phoneNumbersWithoutPlaceHolder.find(
      (phone) => phone.number.length < 10
    );

    let phoneListValid = !invalidPhoneNumber;
    if (phoneListValid) {
      // check for duplicated emails
      const phoneReduced = phoneNumbersWithoutPlaceHolder.map((x) => x.number);
      phoneListValid = !this.checkIfDuplicateExists(phoneReduced);
    }

    return emailListValid && phoneListValid;
  }

  ngOnDestroy() {}

  /**
   * Deletes a given email from the array.
   */
  deleteEmail(email) {
    const index = this.emails.indexOf(email);
    this.emails.splice(index, 1);
    this.emails = [].concat(this.emails);
    this.unsaved = true;
  }

  /**
   * Deletes a given phone number from the array.
   */
  deletePhoneNumber(phoneNumber) {
    const index = this.phoneNumbers.indexOf(phoneNumber);
    this.phoneNumbers.splice(index, 1);
    this.phoneNumbers = [].concat(this.phoneNumbers);
    this.unsaved = true;
  }

  /**
   * Handler of the keyup event of the email input. Checks that the email is not
   * empty and creates an empty email placeholder if needed.
   */
  onKeyUp(email: {
    email?: string;
    saved?: boolean;
    isPlaceHolder?: boolean;
    showButton?: boolean;
  }) {
    if (email.email && email.email.length > 0) {
      email.isPlaceHolder = false;
      this.searchForPlaceholder();
    }
  }

  /**
   * Handler of the keyup event of the phone number input. Checks that the number
   * is not empty and creates an empty phone placeholder if needed.
   */
  onKeyUpPhoneNumber(number: {
    number?: string;
    saved?: boolean;
    isPlaceHolder?: boolean;
    showButton?: boolean;
  }) {
    if (number.number && number.number.length > 0) {
      number.isPlaceHolder = false;
      this.searchForPlaceholderPhoneNumber();
    }
  }

  /**
   * Flag indicating whether an email has already been inserted or not.
   */
  isDuplicatedEmail(email) {
    return this.emails.filter((e) => e.email == email.email).length > 1;
  }

  /**
   * Flag indicating whether a phone number has already been inserted or not.
   */
  isDuplicatedPhone(phone) {
    return this.phoneNumbers.filter((e) => e.number == phone.number).length > 1;
  }

  /**
   * When the form is submitted it will use its values to store them on the shared
   * object and then navigate to the next step.
   */
  saveChanges() {
    this.isLoading = true;

    this.model.notificationEmails = this.emails
      .filter((x) => x.email)
      .map((x) => x.email);
    this.model.notificationPhoneNumbers = this.phoneNumbers
      .filter((x) => x.number)
      .map((x) => x.number);

    this.progress.notificationsCompleted = true;
    this.changeCurrentStep.emit('contactBilling');
  }

  /**
   * Handler of the focus input event. Not implemented
   */
  onFocusEmail() {
    console.log('Email focused');
  }

  /**
   * Handler of the focus input event. Not implemented
   */
  onFocusPhoneNumber() {
    console.log('Phone focused');
  }
}
