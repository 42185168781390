export interface NewSiteTicketProgress {
  websiteCompleted: boolean;
  notificationsCompleted: boolean;
  billingContactCompleted: boolean;
  spanishChatCompleted: boolean;
  analyticsCompleted: boolean;
  cmsCompleted: boolean;
  responseTimeCompleted: boolean;
  specialRequestCompleted: boolean;
}

export interface NewSiteTicket {
  billingContactName: string;
  billingEmails: string[];

  notificationEmails: string[];
  notificationPhoneNumbers: string[];

  spanishChatActive: boolean;
  websiteUrls: string[];
  ga4MeasurementId?: string;
  ga4ApiSecret?: string;
  cms: string;
  responseTimeAnswer: string;
  specialRequests: string;
  specialRequestAttachments: any[];
}
export function getNewSiteTicket() {
  return {
    billingEmails: [],
    notificationEmails: [],
    notificationPhoneNumbers: [],
    websiteUrls: [],
    specialRequestAttachments: [],
  } as NewSiteTicket;
}
