import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export type WhiteLabel = {
  id: string;
  provider: string;
  logo: string;
  whiteLogo: string;
  domain: string;
  reviewDomain: string;
  authUrl?: string;
  enableHubspotFeedback: boolean;
};

let MAPPINGS: { [key: string]: WhiteLabel } = {
  clientChatLive: {
    id: 'clientChatLive',
    domain: 'login.clientchatlive.com',
    reviewDomain: 'clientchatlive.com',
    provider: 'ClientChatLive',
    logo: '/assets/img/whitelabel/logo-ccl.png',
    whiteLogo: '/assets/img/whitelabel/logo-header-white.png',
    enableHubspotFeedback: true,
  },
  chatLeadsPro: {
    id: 'chatLeadsPro',
    domain: 'login.chatleadspro.com',
    reviewDomain: 'chatleadspro.com',
    provider: 'ChatLeadsPro',
    logo: '/assets/img/whitelabel/login-dev.png',
    whiteLogo: '/assets/img/whitelabel/login-dev.png',
    enableHubspotFeedback: false,
  },
  compulselegal: {
    id: 'compulselegal',
    domain: 'compulselegal-login.chatleadspro.com',
    reviewDomain: 'chatleadspro.com',
    provider: 'ChatLeadsPro',
    logo: '/assets/img/whitelabel/compulse.png',
    whiteLogo: '/assets/img/whitelabel/compulse.png',
    enableHubspotFeedback: true,
  },
  rizeupmedia: {
    id: 'rizeupmedia',
    domain: 'rizeupmedia-login.chatleadspro.com',
    reviewDomain: 'chatleadspro.com',
    provider: 'ChatLeadsPro',
    logo: '/assets/img/whitelabel/rizeupmedia.png',
    whiteLogo: '/assets/img/whitelabel/rizeupmedia.png',
    enableHubspotFeedback: false,
  },
};

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  whiteLabel: WhiteLabel;
  isWhiteLabel: boolean = true;

  constructor() {
    this.setWhiteLabelSettings(document.location.origin);
    this.whiteLabel.authUrl = this.getWhiteLabelAuthUrl(this.whiteLabel.domain);
  }

  setWhiteLabelSettings(origin: string) {
    if (origin.includes('dlm')) {
      this.whiteLabel = MAPPINGS['digitalLawMarketing'];
    } else if (origin.includes('sokolove')) {
      this.whiteLabel = MAPPINGS['sokolove'];
    } else if (origin.includes('compulselegal')) {
      this.whiteLabel = MAPPINGS['compulselegal'];
    } else if (origin.includes('rizeupmedia')) {
      this.whiteLabel = MAPPINGS['rizeupmedia'];
    } else if (origin.includes('chatleadspro.com')) {
      this.whiteLabel = MAPPINGS['chatLeadsPro'];
    } else {
      this.whiteLabel = MAPPINGS['clientChatLive'];
      this.isWhiteLabel = false;
    }
  }

  getWhiteLabelAuthUrl(domain: string): string {
    if (domain.replace(/[^\.]/g, '').length > 1) {
      const splittedDomain = this.whiteLabel.domain.split('.');
      return `https://${splittedDomain[0]}.${splittedDomain[1]}.${splittedDomain[2]}/#/sign-in`;
    }
    return `${environment.authSubdomain}${this.whiteLabel.domain}/#/sign-in`;
  }
}
