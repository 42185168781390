import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { mergeMap, map, shareReplay, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { User, IdUser } from './user';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { memoize } from '../shared/memoize-decorator';

/**
 * This service is used to sync users from the platform and firebase users.
 */
@Injectable()
export class UserService {
  private baseURL = environment.cclRoot + '/drf/';

  private userCollection: AngularFirestoreCollection<User>;
  collectionName = 'users';
  _user$: Observable<User>;
  _userWithId$: Observable<User>;

  /**
   * The current logged user with info from the a firebase doc
   */
  currentUserWithId$: Observable<IdUser>;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.userCollection = this.afs.collection<User>(this.collectionName);

    this.currentUserWithId$ = this.authService.uid$.pipe(
      mergeMap((id) =>
        this.userCollection
          .doc(id)
          .valueChanges()
          .pipe(map((user) => ({ id, ...(user as any) } as IdUser)))
      )
    );
  }

  getDoc(id: string): AngularFirestoreDocument<User> {
    return this.userCollection.doc(id);
  }

  get doc$(): Observable<AngularFirestoreDocument<User>> {
    return this.authService.uid$.pipe(map((id) => this.getDoc(id)));
  }

  getIdUser$(id: string): Observable<IdUser> {
    return this.getDoc(id)
      .valueChanges()
      .pipe(map((user) => ({ id, ...user } as IdUser)));
  }

  getUser$(id: string): Observable<User> {
    return this.getDoc(id).valueChanges();
  }

  get user$(): Observable<User> {
    if (!this._user$) {
      this._user$ = this.doc$.pipe(
        mergeMap((doc) => doc.valueChanges()),
        shareReplay(1)
      );
    }
    return this._user$;
  }

  get userWithId$(): Observable<User> {
    if (!this._userWithId$) {
      this._userWithId$ = this.doc$.pipe(
        mergeMap((doc) => doc.valueChanges()),
        shareReplay(1)
      );
    }
    return this._userWithId$;
  }

  getAllUsers(): Observable<IdUser[]> {
    return this.afs
      .collection<User>(this.collectionName)
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  removeUserFromGroup(groupId: number, email: string) {
    return this.http.delete<any>(
      `${this.baseURL}group/${groupId}/remove_user`,
      {
        params: { email: email },
      }
    );
  }

  updateUser(id: string, data: Partial<User>): Promise<void> {
    return this.getDoc(id).update(data);
  }

  findByEmail$(email: string): Observable<User> {
    return this.afs
      .collection<User>(this.collectionName, (ref) =>
        ref.where('email', '==', email)
      )
      .snapshotChanges()
      .pipe(
        map((result) => {
          const user = null;
          if (result.length > 0) {
            return result[0].payload.doc.data();
          }
          return user;
        })
      );
  }

  @memoize()
  getUserImageUrlFromEmail$(email: string): Observable<string> {
    return this.findByEmail$(email).pipe(
      take(1),
      map((user) => {
        let imgUrl: string = null;
        console.log('user is', user);
        if (user && user.profilePictureUrl) {
          imgUrl = user.profilePictureUrl;
        }

        return imgUrl;
      })
    );
  }
}
