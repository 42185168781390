import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { LocationService } from './location.service';
import { MyBusinessService } from './my-business.service';
import { NotificationService } from './notification.service';
import { TemplateService } from './template.service';
import { CustomerService } from './customer.service';
import { BillingContractService } from './billing-contract.service';
import { SupportTicketService } from './support-ticket.service';
import { SpinnerService } from './spinner.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, FlexLayoutModule],
  exports: [HttpClientModule, FlexLayoutModule],
  providers: [
    AccountService,
    AuthService,
    UserService,
    LocationService,
    MyBusinessService,
    NotificationService,
    TemplateService,
    CustomerService,
    BillingContractService,
    SupportTicketService,
    SpinnerService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. You should only import Core modules in the AppModule only.'
      );
    }
  }
}
