import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GroupUsersService } from '../../service/group-users.service';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { finalize } from 'rxjs/operators';
import { AVAILABLE_ROLES } from 'src/app/core/user';
import { Notify } from 'src/app/shared/services/notify.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-group-user-creator',
  templateUrl: './group-user-creator.component.html',
  styleUrls: ['./group-user-creator.component.scss'],
})
export class GroupUserCreatorComponent implements OnInit {
  isLoading = true;
  currentStep: 'searchEmail' | 'createNewUser' | 'add_existing_user' =
    'searchEmail';
  searchEmailForm: FormGroup;
  addExistingUserForm: FormGroup;
  newUserForm: FormGroup;
  groupId: number;
  errorMessage: string;
  roles = AVAILABLE_ROLES;

  existingUser: any;

  constructor(
    private fb: FormBuilder,
    private groupUsersService: GroupUsersService,
    private groupService: CclSelectedGroupService,
    private notify: Notify,
    public dialogRef: MatDialogRef<GroupUserCreatorComponent>
  ) {}

  /**
   * Gets the current group ID and initializes the form group.
   */
  ngOnInit(): void {
    this.groupService.selectedId.subscribe((groupId) => {
      this.groupId = groupId;
      this.isLoading = false;
    });
    this.searchEmailForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  /**
   * Searchs if the given email belong to an existing user, if it is an operator
   * it will add it as an existing user else if will be created as a new one.
   */
  searchEmail() {
    const email = this.searchEmailForm.get('email').value;
    this.errorMessage = null;

    this.isLoading = true;
    this.groupUsersService
      .searchUserByEmail(this.groupId.toString(), email)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response) => {
          if (response.success) {
            if (response.operator) {
              this.continueToAddExistingUser(response.operator);
            } else {
              this.continueToCreateNewUser();
            }
          } else {
            if (response.errors && Object.keys(response.errors).length) {
              const firstKey = Object.keys(response.errors)[0];
              const firstError = response.errors[firstKey][0];
              this.errorMessage = firstError;
            } else {
              this.errorMessage =
                'An error has ocurred, please try again later';
            }
          }
        },
        (_) => {
          this.errorMessage = 'An error has ocurred, please try again later';
        }
      );
  }

  /**
   * Sets necesary variables and navigates to 'add_existing_user' step.
   */
  continueToAddExistingUser(operator: any) {
    this.existingUser = operator;
    this.searchEmailForm.controls['email'].disable();
    this.currentStep = 'add_existing_user';
    this.addExistingUserForm = this.fb.group({
      role: [null, [Validators.required]],
    });
  }

  /**
   * When the form is submitted, adds an existing user with the role from
   * the form.
   */
  addExistingUser() {
    const operatorUref = this.existingUser.uref;
    const role = this.addExistingUserForm.get('role').value;
    this.isLoading = true;
    this.errorMessage = null;
    this.groupUsersService
      .addExstingUserToGroup(this.groupId.toString(), operatorUref, role)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (_) => {
          this.notify.success('Invite sent correctly');
          this.dialogRef.close();
        },
        (_) => {
          this.errorMessage = 'An error has ocurred, please try again later';
        }
      );
  }

  /**
   * Sets necesary variables and navigates to 'createNewUser' step.
   */
  continueToCreateNewUser() {
    this.searchEmailForm.controls['email'].disable();
    this.currentStep = 'createNewUser';
    this.newUserForm = this.fb.group({
      role: [null, [Validators.required]],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
    });
  }

  /**
   * When the form is submitted, uses its values to create a new user.
   */
  createNewUser() {
    const email = this.searchEmailForm.get('email').value;
    const data = { ...this.newUserForm.value, email: email };
    this.isLoading = true;
    this.errorMessage = null;
    this.groupUsersService
      .createNewUserInGroup(this.groupId.toString(), data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (_) => {
          this.notify.success('Invite sent correctly');
          this.dialogRef.close();
        },
        (_) => {
          this.errorMessage = 'An error has ocurred, please try again later';
        }
      );
  }
}
