import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { LiveChatService } from 'src/app/live-chat-leads/shared/live-chat.service';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/core/user.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { CclInviteWithId } from 'src/app/shared/models/invite';

export interface UserGroup {
  id: string;
  operatorId?: number;
  firstName: string;
  lastName: string;
  cclRole?: string;
  email: string;
  status: 'pending' | 'accepted';
}

@Injectable({
  providedIn: 'root',
})
export class GroupUsersService {
  private baseURL = environment.cclRoot + '/drf/';

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private liveChatService: LiveChatService,
    private userService: UserService
  ) {}

  /**
   * Gets all the invites from Firestore of the given group.
   */
  private invitesFromGroup(groupId: string): Observable<CclInviteWithId[]> {
    return this.afs
      .collection<CclInviteWithId>('ccl-invites', (ref) =>
        ref.where('groupId', '==', Number(groupId))
      )
      .snapshotChanges()
      .pipe(
        map((snap) => {
          return snap.map((invite) => {
            return {
              id: invite.payload.doc.id,
              ...invite.payload.doc.data(),
            } as CclInviteWithId;
          });
        })
      );
  }

  /**
   * Gets all the CCL users (from platform, Firestore and pending invites).
   */
  getUsersFromGroup(groupId: string): Observable<UserGroup[]> {
    const usersFromPlatform$: Observable<any[]> =
      this.liveChatService.getUsersFromGroup(groupId);

    const userFromFirebase$ = this.userService.getAllUsers();
    const groupInvites$ = this.invitesFromGroup(groupId);

    return combineLatest(
      usersFromPlatform$,
      userFromFirebase$,
      groupInvites$
    ).pipe(
      map(([usersFromPlatform, usersFromFirebase, _invites]) => {
        const emailsFromPlatform: String[] = usersFromPlatform.map(
          (u) => u.email
        );
        const users: UserGroup[] = usersFromFirebase
          .filter((u) => emailsFromPlatform.includes(u.email))
          .map((u) => {
            const user = { ...u, status: 'accepted' } as UserGroup;
            const platformUser = usersFromPlatform.find(
              (_u) => _u.email == u.email
            );
            if (platformUser) {
              user.operatorId = platformUser.operator_id;
            }
            return user;
          });

        const invites: UserGroup[] = _invites.map((u) => {
          return { ...u, status: 'pending' } as UserGroup;
        });

        return users.concat(invites);
      })
    );
  }

  /**
   * Gets all the active user of the given group, an active user must have
   * operatorID.
   */
  getActiveUsersFromGroup(groupId: string): Observable<UserGroup[]> {
    return this.getUsersFromGroup(groupId).pipe(
      map((users) => users.filter((u) => u.operatorId))
    );
  }

  /**
   * Gets a user found by its email.
   */
  searchUserByEmail(
    groupId: string,
    email: string
  ): Observable<{ success: boolean; errors?: string[]; operator: any }> {
    return this.http.post<any>(`${this.baseURL}group/${groupId}/search_email`, {
      email: email,
    });
  }

  /**
   * Creates a user in the given group.
   */
  createNewUserInGroup(groupId: string, userData: string): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}group/${groupId}/create_new_user`,
      userData
    );
  }

  /**
   * Adds an operator to a group with the specified role.
   * @param groupId The ID of the group to add.
   * @param operatorUref The URef of the operator to be added.
   * @param role The role that is going to have the operator on the group.
   */
  addExstingUserToGroup(
    groupId: string,
    operatorUref: string,
    role: string
  ): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}group/${groupId}/add_existing_user_to_group/${operatorUref}`,
      {
        role: role,
      }
    );
  }
}
