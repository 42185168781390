import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { concatMap, catchError, take, map } from 'rxjs/operators';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { AuthService } from './core/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private router: Router
  ) {}

  /**
   * Allows the navigation to given route only if the current user is
   * authenticated.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkLogin(this.router.url, route.queryParamMap.get('token'));
  }

  /**
   * Uses the same 'User is authenticated?' to allow navigation to child routes.
   */
  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }

  /**
   * Authenticate or check if it is authenticated.
   * @param url String with the redirection URL sent to the login if the login fails.
   * @param token The new access_token used to authenticate.
   * @returns An obervable of a boolean value that will be true if it was possible to
   * authenticate with the token or if it is already authenticated.
   */
  checkLogin(url: string, token: string) {
    // Get token from query params and use it to log in
    const errorCatcher = (err) => {
      console.error(err);
      this.authService.kickOut(url);
      return of(false);
    };

    if (token) {
      // Remove any existing access tokens;
      // auth service will get a new one if necessary
      localStorage.removeItem('access_token');
      return from(this.afAuth.signInWithCustomToken(token)).pipe(
        take(1),
        map((_) => {
          return true;
        }),
        catchError(errorCatcher)
      );
    }

    return this.afAuth.authState.pipe(
      concatMap((authUser) => {
        // If logged in, activate
        if (authUser) {
          return of(true);
        }

        // Navigate to the login page,
        // with the attempted URL for redirecting
        this.authService.kickOut(url);
        return of(false);
      }),
      catchError(errorCatcher)
    );
  }
}
