import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { ReviewsGuard } from './reviews-guard.service';
import { LiveChatGuard } from './live-chat-container/guards/live-chat-guard.service';
import { UserRedirectionComponent } from './user-redirection/user-redirection.component';

const appRoutes: Routes = [
  {
    path: 'decline-referral',
    canActivate: [AuthGuard, LiveChatGuard],
    loadChildren: () =>
      import('./decline-lead/decline-lead.module').then(
        (m) => m.DeclineLeadModule
      ),
  },
  {
    path: 'stripe',
    loadChildren: () =>
      import('./stripe/stripe.module').then((m) => m.StripeModule),
  },
  {
    path: 'reviews',
    loadChildren: () =>
      import('./reviews-container/reviews-container.module').then(
        (m) => m.ReviewsContainerModule
      ),
  },
  {
    path: 'live-chat',
    loadChildren: () =>
      import('./live-chat-container/live-chat-container.module').then(
        (m) => m.LiveChatContainerModule
      ),
  },
  {
    path: 'google-link',
    loadChildren: () =>
      import('./google-account-link/google-account-link.module').then(
        (m) => m.GoogleAccountLinkModule
      ),
  },
  {
    path: 'redirect',
    component: UserRedirectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'redirect',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, ReviewsGuard, LiveChatGuard],
})
export class AppRoutingModule { }
