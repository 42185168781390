import { Component, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { LiveChatSite } from '../../live-chat-leads/shared/live-chat-site';
import { UserService } from 'src/app/core/user.service';
import { IdUser } from 'src/app/core/user';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { Notify } from 'src/app/shared/services/notify.service';
import { TicketsService } from 'src/app/ccl-service-tickets/tickets.service';
import {
  TICKET_CATEGORIES_LABELS,
  TICKET_CATEGORIES_VALUES,
} from 'src/app/ccl-service-tickets/enums/TicketCategories';
import {
  TICKET_PRIORITY_LABELS,
  TICKET_PRIORITY_VALUES,
} from 'src/app/ccl-service-tickets/enums/TicketPriority';
import { CclGroupsStoreService } from 'src/app/live-chat-container/services/ccl-groups-store.service';
import { SUPPORT_TICKET_TYPE } from 'src/app/core/enum/SupportTicketType';

@Component({
  selector: 'app-site-editor',
  templateUrl: './site-editor.component.html',
  styleUrls: ['./site-editor.component.scss'],
})
export class SiteEditorComponent implements OnInit {
  defaultGroup: any;
  user: IdUser;
  requestForm: FormGroup;
  availableSites: LiveChatSite[];
  siteEmails$: Observable<string[]>;
  existingEmails: string[];
  selectedEmails: string[] = [''];
  isLoading = false;

  ticketCategoriesLabels = TICKET_CATEGORIES_LABELS;
  ticketCategoriesValues = TICKET_CATEGORIES_VALUES;
  ticketPriorityLabels = TICKET_PRIORITY_LABELS;
  ticketPriorityValues = TICKET_PRIORITY_VALUES;

  constructor(
    private userService: UserService,
    private readonly _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<SiteEditorComponent>,
    public notify: Notify,
    private groupService: CclSelectedGroupService,
    private groupStore: CclGroupsStoreService,
    @Inject(MAT_DIALOG_DATA) public data: { sites: LiveChatSite[] },
    private readonly _ticketsService: TicketsService
  ) {}

  /**
   * Gets the current user and group, the provided MatDialog data and creates
   * the FormGroup used in the component.
   */
  ngOnInit() {
    this.groupStore.groupsForUser$.subscribe((groups) => {
      this.groupService.selectedId.subscribe((id) => {
        this.defaultGroup = groups.find((g) => g.id == id);
      });
    });
    this.userService.currentUserWithId$.subscribe((user) => {
      this.user = user;
    });
    this.availableSites = this.data.sites;
    this.requestForm = this._formbuilder.group({
      sites: [this.availableSites, Validators.required],
      emails: this._formbuilder.array([this.createEmail()]),
      oldNotificationTargetEmails: [[]],
      otherRequest: [''],
      priority: ['', Validators.required],
      category: ['', Validators.required],
    });

    this.sitesChanged();
  }

  /**
   * Creates the email from control.
   */
  createEmail(): FormGroup {
    return this._formbuilder.group({
      email: '',
    });
  }

  /**
   * Callback triggered when the sites from control changes. Refreshshing an
   * auxiliary array used to store all the email targets from the selected
   * sites.
   */
  sitesChanged() {
    const existingEmails = this.requestForm.value.sites.reduce(
      (acum, site) => acum.concat(site.notifications),
      []
    );
    this.existingEmails = [...new Set<string>(existingEmails)];
  }

  /**
   * Callback triggered when the oldNotificationTargetEmails from control
   * changes. Allowing to delete some of these old notification targets.
   */
  emailsToRemoveChanged(siteEmailsList: any) {
    const emails = siteEmailsList.selectedOptions.selected.map((i) => i.value);
    this.requestForm.get('oldNotificationTargetEmails').setValue(emails);
  }

  /**
   * Adds an email from the form's email control at the end of the array of
   * emails.
   */
  addEmail() {
    const emails = this.requestForm.get('emails') as FormArray;
    emails.push(this.createEmail());
  }

  /**
   * Removes an email from the form's email control based on its position in
   * the array of emails.
   */
  removeEmail(index: number) {
    const emails = this.requestForm.get('emails') as FormArray;
    emails.removeAt(index);
  }

  /**
   * When a form is submitted this function uses its values to format a
   * support ticket object and store it on the database.
   */
  submitForm(): void {
    this.isLoading = true;
    const {
      sites,
      emails,
      oldNotificationTargetEmails,
      otherRequest,
      priority,
      category,
    } = this.requestForm.value;
    const { id, firstName, lastName, email } = this.user;
    const emailJoin = emails
      .filter((e) => e.email)
      .map((e) => e.email)
      .join(', ');
    const siteNames = sites.map((site) => site.name);
    const description = `New Emails: ${emailJoin}
    Emails to Remove: ${oldNotificationTargetEmails.join(', ')}
    Other Request: ${otherRequest}
    `;
    const newSupportTicket = {
      subject: 'Update Site Settings Request',
      sites: siteNames,
      newNotificationTargetEmail: emailJoin,
      description,
      user: id,
      email,
      userName: `${firstName} ${lastName}`,
      groupId: this.defaultGroup.id,
      groupName: this.defaultGroup.name,
      groupClientHref: this.defaultGroup.client_uref,
      category,
      priority,
      oldNotificationTargetEmails,
      otherRequest,
      type: SUPPORT_TICKET_TYPE.SERVICE,
    };
    this.createSupportTicket(newSupportTicket);
  }

  /**
   * Store the given support ticket on its Firestore collection.
   */
  createSupportTicket(supportTicket) {
    this._ticketsService
      .createSupportTicket(supportTicket)
      .then((_) => {
        this.isLoading = false;
        this.dialogRef.close(true);
      })
      .catch((_) =>
        this.notify.error("Request couldn't be sent. Please try again")
      );
  }

  /**
   * Function used to define the order shown by an ngFor directive. In this
   * case it will show the elements in the same order they are provided,
   * avoiding the Angular's automatic asc order by name.
   */
  originalOrder = (): number => {
    return 0;
  };

  /**
   * Gets the controls (AbstractControl[]) of the form's email control.
   */
  get emailsControls(): AbstractControl[] {
    const emailsControl = this.requestForm.get('emails') as FormArray;
    return emailsControl.controls;
  }
}
