import { Component, OnInit, Input } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { NavLink } from '../../core/nav-link';
import { NavLinkNode } from '../../core/nav-link-node';
import { UserService } from 'src/app/core/user.service';
import { User } from 'src/app/core/user';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { LiveChatService } from 'src/app/live-chat-leads/shared/live-chat.service';
import { AngularFirestore } from '@angular/fire/firestore';

/**
 * This component is an isolated implementation of the Material tree (MatTree).
 * More info: https://material.angular.io/components/tree/api
 */
@Component({
  selector: 'app-nav-link-tree',
  templateUrl: './nav-link-tree.component.html',
  styleUrls: ['./nav-link-tree.component.css'],
})
export class NavLinkTreeComponent implements OnInit {
  @Input() navLinks: NavLink[];

  treeControl: FlatTreeControl<NavLinkNode>;
  treeFlattener: MatTreeFlattener<NavLink, NavLinkNode>;
  dataSource: MatTreeFlatDataSource<NavLink, NavLinkNode>;

  currentUser: User;

  childContext: any = {};

  /**
   * Initializes needed properties for Material Tree and sets the child context.
   */
  constructor(
    public cclGroupService: CclSelectedGroupService,
    public lcService: LiveChatService,
    public userService: UserService,
    public afs: AngularFirestore
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this._getLevel,
      this._isExpandable,
      this._getChildren
    );
    this.treeControl = new FlatTreeControl<NavLinkNode>(
      this._getLevel,
      this._isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    this.childContext = {
      cclGroupService,
      lcService,
      userService,
      afs,
    };
  }

  /**
   * Gets the current user, initializes component properties and expand the
   * tree.
   */
  ngOnInit() {
    this.userService.currentUserWithId$.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.dataSource.data = this.navLinks;
      this.treeControl.expandAll();
    });
  }

  /**
   * Creates a new node based on the given one with the given level.
   */
  transformer = (node: NavLink, level: number) => {
    return new NavLinkNode(
      !!node.children,
      level,
      node.path,
      node.label,
      node.icon
    );
  };

  /*
    Level, Expandable and Children getters from the given node.
  */
  private _getLevel = (node: NavLinkNode) => node.level;

  private _isExpandable = (node: NavLinkNode) => node.expandable;

  private _getChildren = (node: NavLink): NavLink[] => node.children;

  /**
   * Flag indicating whether the given node has child nodes.
   */
  hasChild = (_: number, _nodeData: NavLinkNode) => _nodeData.expandable;
}
