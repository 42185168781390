import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MainFirestoreService<Entity> {
  constructor(protected readonly _firestore: AngularFirestore) {}

  protected create(route: string, newDocument: Entity) {
    return this._firestore.collection(route).add(newDocument);
  }

  protected createReference(collectionRoute: string, referenceId?: string) {
    const newDocumentId = referenceId
      ? referenceId
      : this._firestore.createId();
    return this._firestore.collection(collectionRoute).doc(newDocumentId).ref;
  }

  protected update(
    collectionRoute: string,
    documentId: string,
    newDocument: Entity
  ) {
    return this._firestore
      .collection(collectionRoute)
      .doc(documentId)
      .set(newDocument, { merge: true });
  }

  protected delete(collectionRoute: string, documentId: string) {
    return this._firestore.collection(collectionRoute).doc(documentId).delete();
  }

  protected get(route: string, queryFunction?: QueryFn) {
    return this._firestore
      .collection(route, queryFunction)
      .snapshotChanges()
      .pipe(
        map((snapshot) =>
          snapshot.map(
            (doc) =>
              ({
                id: doc.payload.doc.id,
                ...(doc.payload.doc.data() as any),
              } as Entity)
          )
        )
      );
  }

  protected getOne(
    collectionRoute: string,
    documentRoute: string,
    queryFunction?: QueryFn
  ) {
    return this._firestore
      .collection(collectionRoute, queryFunction)
      .doc(documentRoute)
      .snapshotChanges()
      .pipe(
        map(
          (snapshot) =>
            ({
              id: snapshot.payload.id,
              ...(snapshot.payload.data() as any),
            } as Entity)
        ),
        take(1)
      );
  }

  protected getReference(collectionRoute: string, documentId: string) {
    return this._firestore.collection(collectionRoute).doc<Entity>(documentId)
      .ref;
  }

  protected batch(
    createQueries: any[],
    updateQueries: any[],
    deleteQueries: any[]
  ) {
    const batch = this._firestore.firestore.batch();
    createQueries.forEach(({ reference, document }) =>
      batch.set(reference, document)
    );
    updateQueries.forEach(({ reference, document }) =>
      batch.update(reference, document)
    );
    deleteQueries.forEach(({ reference }) => batch.delete(reference));
    return batch.commit();
  }

  protected batchCreate(batchQueries: any[]) {
    const batch = this._firestore.firestore.batch();
    batchQueries.forEach(({ reference, document }) => {
      batch.set(reference, document);
    });
    return batch.commit();
  }

  protected batchUpdate(batchQueries: any[]) {
    const batch = this._firestore.firestore.batch();
    batchQueries.forEach(({ reference, document }) => {
      batch.update(reference, document);
    });
    return batch.commit();
  }

  protected batchDelete(batchQueries: any[]) {
    const batch = this._firestore.firestore.batch();
    batchQueries.forEach(({ reference }) => {
      batch.delete(reference);
    });
    return batch.commit();
  }
}
