import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AVAILABLE_ROLES } from 'src/app/core/user';
import { UserService } from 'src/app/core/user.service';
import { Notify } from 'src/app/shared/services/notify.service';

@Component({
  selector: 'app-group-user-editor',
  templateUrl: './group-user-editor.component.html',
  styleUrls: ['./group-user-editor.component.scss'],
})
export class GroupUserEditorComponent implements OnInit {
  isLoading = true;
  user: any;
  editUserForm: FormGroup;
  roles = AVAILABLE_ROLES;
  errorMessage = null;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GroupUserEditorComponent>,
    private userService: UserService,
    private notify: Notify,
    @Inject(MAT_DIALOG_DATA) public data: { user: any }
  ) {}

  /**
   * Initializes the form group from the MatDialog injected data.
   */
  ngOnInit(): void {
    this.user = this.data.user;
    this.editUserForm = this.fb.group({
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      role: [this.user.cclRole, Validators.required],
    });
    this.isLoading = false;
  }

  /**
   * When the form is submitted, uses its values to update the first name, last
   * name and role of the current user.
   */
  updateUser() {
    const form = this.editUserForm.value;
    const role = form.role;
    const firstName = form.firstName;
    const lastName = form.lastName;
    this.userService
      .updateUser(this.user.id, {
        cclRole: role,
        firstName,
        lastName,
      })
      .then(() => {
        this.dialogRef.close();
        this.notify.success('User updated correctly');
      });
  }
}
