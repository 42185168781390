import { Component, OnInit } from '@angular/core';

import { NavLinks } from './nav-links';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit {
  tabs = NavLinks;

  constructor() {}

  ngOnInit() {}
}
