import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewSiteTicket, NewSiteTicketProgress } from '../NewSiteTicketModel';

@Component({
  selector: 'app-new-site-ticket-spanish-chat',
  templateUrl: './new-site-ticket-spanish-chat.component.html',
  styleUrls: ['./new-site-ticket-spanish-chat.component.scss'],
})
export class NewSiteTicketSpanishChatComponent implements OnInit {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;
  @Output() changeCurrentStep = new EventEmitter<string>();
  isLoading = true;
  isCompleted = false;
  spanishChatActive = false;

  constructor() {}

  ngOnInit() {
    this.spanishChatActive = this.model.spanishChatActive;
    this.isCompleted = this.progress.spanishChatCompleted === true;
    window.setTimeout(() => (this.isLoading = false), 500);
  }

  ngOnDestroy() {}

  /**
   * When the "form" is submitted, stores its values and continues to the
   * next step.
   */
  onSubmit() {
    const spanishChatActive = this.spanishChatActive === true;
    this.model.spanishChatActive = spanishChatActive;

    this.progress.spanishChatCompleted = true;
    this.changeCurrentStep.emit('analytics');
  }
}
