import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FiconsModule } from './ficons.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { MinTimeDirective } from './min-time.directive';
import { PhoneNumberDirective } from './phone-number.directive';
import { CouponDirective } from './coupon.directive';
import { PasswordCheckerComponent } from './password-checker/password-checker.component';
import { PhonePipe } from './phone.pipe';
import { SelectAllCheckboxComponent } from './select-all-checkbox.component';
import { NavLinkTreeComponent } from './nav-link-tree/nav-link-tree.component';
import { ScrollableDirective } from '../core/scrollable.directive';
import { NiceTimePipe } from '../conversations/pipes/nice-time.pipe';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
// import { MyDateRangePickerModule } from "mydaterangepicker";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatSelectModule } from '@angular/material/select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TextMaskModule } from 'angular2-text-mask';
import { CclGroupsDropdownComponent } from '../live-chat-leads/ccl-groups-dropdown/ccl-groups-dropdown.component';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { SelectAllCheckboxWithoutControlComponent } from './select-all-checkbox-without-control.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { AvatarForEmailComponent } from './avatar-for-email/avatar-for-email.component';
import { StepperNavigationBarComponent } from './stepper-navigation-bar/stepper-navigation-bar.component';
import { CustomTimePickerComponent } from './custom-time-picker/custom-time-picker.component';
import { MatBadgeModule } from '@angular/material/badge';
import { LeadTypeIconComponent } from './lead-type-icon/lead-type-icon.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Material modules
    MatAutocompleteModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatSnackBarModule,
    MatChipsModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSlideToggleModule,
    // MyDateRangePickerModule,
    PerfectScrollbarModule,
    MatDialogModule,
    MatSelectModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMatSelectSearchModule,
    MatInputModule,
    MatStepperModule,
    MatTabsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSortModule,
    MatGridListModule,
    MatTooltipModule,
    MatRadioModule,
    MatBadgeModule,
    TextMaskModule,
    FontAwesomeModule,
    AutocompleteLibModule,
    FiconsModule,
  ],
  declarations: [
    AlertDialogComponent,
    MinTimeDirective,
    PhoneNumberDirective,
    CouponDirective,
    PasswordCheckerComponent,
    PhonePipe,
    SelectAllCheckboxComponent,
    SelectAllCheckboxWithoutControlComponent,
    NavLinkTreeComponent,
    ScrollableDirective,
    NiceTimePipe,
    CclGroupsDropdownComponent,
    ErrorMessageComponent,
    UserAvatarComponent,
    AvatarForEmailComponent,
    StepperNavigationBarComponent,
    CustomTimePickerComponent,
    LeadTypeIconComponent,
  ],
  entryComponents: [AlertDialogComponent, PasswordCheckerComponent],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MinTimeDirective,
    PhoneNumberDirective,
    CouponDirective,
    ScrollableDirective,
    SelectAllCheckboxComponent,
    SelectAllCheckboxWithoutControlComponent,
    PhonePipe,
    NavLinkTreeComponent,
    NiceTimePipe,
    FlexLayoutModule,
    CclGroupsDropdownComponent,
    AutocompleteLibModule,
    ErrorMessageComponent,
    UserAvatarComponent,
    AvatarForEmailComponent,
    StepperNavigationBarComponent,

    // Material modules
    MatAutocompleteModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatSnackBarModule,
    MatChipsModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    MatMenuModule,
    MatSlideToggleModule,

    // MyDateRangePickerModule,
    PerfectScrollbarModule,
    MatDialogModule,
    MatSelectModule,
    NgxDaterangepickerMd,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatStepperModule,
    MatTabsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTableModule,
    MatGridListModule,
    MatTooltipModule,
    MatRadioModule,
    MatBadgeModule,
    TextMaskModule,
    FontAwesomeModule,
    FiconsModule,
    CustomTimePickerComponent,
    LeadTypeIconComponent,
  ],
})
export class SharedModule {}
