import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() {}

  setState(state: boolean) {
    this.isLoading$.next(state);
  }
}
