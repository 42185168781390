import { Component, OnInit } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { IdUser, isCclGlobalAdmin } from 'src/app/core/user';
import { CclGroupsStoreService } from 'src/app/live-chat-container/services/ccl-groups-store.service';
import { UserService } from 'src/app/core/user.service';
import { LiveChatGroup } from 'src/app/live-chat-container/models/LiveChatGroup';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';

@Component({
  selector: 'app-ccl-groups-dropdown',
  templateUrl: './ccl-groups-dropdown.component.html',
  styleUrls: ['./ccl-groups-dropdown.component.css'],
})
export class CclGroupsDropdownComponent implements OnInit {
  disableDropdown = true;
  selectedGroupId: number;
  groups: LiveChatGroup[] = [];
  user: IdUser;

  public filteredGroups: Subject<LiveChatGroup[]> = new ReplaySubject();

  constructor(
    private userService: UserService,
    private cclGroupsStore: CclGroupsStoreService,
    private groupService: CclSelectedGroupService
  ) {}

  ngOnInit() {
    this.userService.currentUserWithId$.subscribe((user) => (this.user = user));
    this.groupService.selectedId.subscribe(
      (selectedGroupId) => (this.selectedGroupId = selectedGroupId)
    );
    this.getGroups();
  }

  onSelectionChange(e) {
    this.groupService.setSelectedId(e.value);
    if (isCclGlobalAdmin(this.user)) {
      localStorage.setItem(this.user.id + '-selectedGroupId', e.value);
    }
  }

  getGroups() {
    this.cclGroupsStore.groupsForUser$.subscribe((groups) => {
      this.groups = groups;
      this.filteredGroups.next(this.groups);
      this.disableDropdown = false;
    });
  }

  filterGroups(e: string) {
    const search = e.toLowerCase();
    if (!search || search.length < 1) {
      this.filteredGroups.next(this.groups);
    }

    this.filteredGroups.next(
      this.groups.filter((acc) => acc.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
