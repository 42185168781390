import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { GroupCustomSenderEditorComponent } from '../group-custom-sender-editor/group-custom-sender-editor.component';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { Notify } from 'src/app/shared/services/notify.service';

/**
 * Component created to update the group's custom senders (Check Firestore collection
 * 'group-email-senders') it will render all the sender at the beggining, let you create
 * a new sender or create, update or delete the current ones. Each time you click a
 * sender, it will focus to that one through the variables ``updateSenderName`` and
 * ``updateSenderId``
 */
@Component({
  selector: 'app-group-custom-sender-settings',
  templateUrl: './group-custom-sender-settings.component.html',
  styleUrls: ['./group-custom-sender-settings.component.scss'],
})
export class GroupCustomSenderSettingsComponent implements OnInit {
  isLoading = true;
  updateSenderId: number = null;
  updateSenderName = null;
  groupId: number;

  senders: any[] = [];

  constructor(
    public cclGroupService: CclSelectedGroupService,
    public dialog: MatDialog,
    public afs: AngularFirestore,
    public notify: Notify
  ) {}

  /**
   * Subscribes to the group's Firestore document ('group-email-senders') and gets
   * all then senders' documents with its ID.
   */
  ngOnInit() {
    this.cclGroupService.selectedId.subscribe((groupId) => {
      this.groupId = groupId;
      this.isLoading = false;

      this.afs
        .collection('group-email-senders', (ref) =>
          ref.where('groupId', '==', this.groupId)
        )
        .snapshotChanges()
        .subscribe((snaps) => {
          this.senders = snaps.map((doc) => {
            const sender: any = doc.payload.doc.data();
            sender.id = doc.payload.doc.id;
            return sender;
          });
        });
    });
  }

  /**
   * Opens a MatDialog to create a new sender.
   */
  onAddEmailClick() {
    const dialogRef = this.dialog.open(GroupCustomSenderEditorComponent, {});
    dialogRef.afterClosed().subscribe((_) => {});
  }

  /**
   * Uses the setted sender name to delete the Firestore document on 'group-email-senders'
   * and show success or fail messages.
   */
  onDeleteEmailSender(emailSenderId: String) {
    this.afs
      .doc(`group-email-senders/${emailSenderId}`)
      .delete()
      .then((_) => {
        this.notify.success('Custom email sender removed');
      })
      .catch((_) => {
        this.isLoading = false;
        this.notify.error("Request couldn't be sent. Please try again");
      });
  }

  /**
   * Empties the ID and name of the sender.
   */
  onCloseUpdateSender() {
    this.updateSenderId = null;
    this.updateSenderName = null;
  }

  /**
   * Sets the ID and name of the sender, data that will be displayed on the view.
   */
  onBeginUpdateSender(emailSenderId: number, name: string) {
    this.updateSenderId = emailSenderId;
    this.updateSenderName = name;
  }

  /**
   * When the form is submitted uses its values to update the Firestore document on
   * 'group-email-senders' and show success or fail messages.
   */
  async onUpdateNameSender() {
    this.afs
      .doc(`group-email-senders/${this.updateSenderId}`)
      .update({
        name: this.updateSenderName,
      })
      .then((_) => {
        this.notify.success('Email name server updated');
        this.onCloseUpdateSender();
      })
      .catch((_) => {
        this.isLoading = false;
        this.notify.error("Request couldn't be sent. Please try again");
      });
  }
}
