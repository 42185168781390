import { Component, OnInit, Input } from '@angular/core';

/**
 * This component renders a provided error message. Seems like is used
 * on some forms validations.
 *
 * TODO: Study the possibility of add this error message to all the forms.
 */
@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {
  @Input() errorMessage: string;
  constructor() {}

  ngOnInit(): void {}
}
