import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { switchMap, tap, map, mapTo, take } from 'rxjs/operators';
import '@firebase/functions';
import firebase from 'firebase';
import { invoices } from 'stripe';

import { AuthService } from '../core/auth.service';
import { environment } from '../../environments/environment';
import { User } from './user';
import { Customer, Source, Subscription, State } from './customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  accountId: string;
  status$: Observable<string>;
  source$: Observable<Source>;
  sub$: Observable<Subscription>;
  customer$: Observable<Customer>;
  handler: any;
  updateStripePlanAndCoupon = firebase
    .functions()
    .httpsCallable('updateStripePlanAndCoupon');
  getUpcomingInvoice = firebase.functions().httpsCallable('getUpcomingInvoice');
  checkCoupon = firebase.functions().httpsCallable('checkCoupon');

  constructor(private afs: AngularFirestore, private authService: AuthService) {
    this.customer$ = this.authService.uid$.pipe(
      switchMap((uid) => this.afs.doc<User>(`users/${uid}`).valueChanges()),
      tap((userData) => (this.accountId = userData.defaultAccountId)),
      switchMap((userData) =>
        this.afs
          .doc<Customer>(`customers/${userData.defaultAccountId}`)
          .valueChanges()
      )
    );

    this.status$ = this.customer$.pipe(
      map((customerData) => {
        if (customerData) return customerData.status;

        return null;
      })
    );

    // gets payment source data to display
    this.source$ = this.customer$.pipe(
      map((customerData) => customerData.source)
    );

    // gets subscription trial dates
    this.sub$ = this.customer$.pipe(
      map((customerData) => customerData.subscription)
    );

    this.handler = StripeCheckout.configure({
      key: environment.stripeToken,
      image: 'https://yw48n.app.goo.gl/4v3Q',
      locale: 'auto',
      source: (source) => {
        this.updateSource(source);
      },
    });
  }

  getCustomerByAccountId(accountId: string): Observable<Customer> {
    return this.afs.doc<Customer>(`customers/${accountId}`).valueChanges();
  }

  updateSource(source: any): Promise<void> {
    return this.afs.doc(`/customers/${this.accountId}`).update({
      sourceId: source ? source.id : firebase.firestore.FieldValue.delete(),
      state: State.Attaching,
    });
  }

  updateSourceByAccountId(accountId: string, source: any): Promise<void> {
    return this.afs.doc(`/customers/${accountId}`).update({
      sourceId: source ? source.id : firebase.firestore.FieldValue.delete(),
      state: State.Attaching,
    });
  }

  openCheckout(panelLabel?: string): void {
    this.customer$.pipe(take(1)).subscribe((customer) => {
      const opts = {
        name: 'CCL Reviews',
        email: customer.email,
        allowRememberMe: false,
      };
      if (panelLabel) {
        opts['panelLabel'] = panelLabel;
      } else {
        if (customer.amount_due) {
          opts['amount'] = customer.amount_due;
        } else {
          opts['panelLabel'] = 'Update';
        }
      }
      this.handler.open(opts);
    });
  }

  openCheckoutByAccountId(accountId: string, panelLabel?: string): void {
    this.getCustomerByAccountId(accountId)
      .pipe(take(1))
      .subscribe((customer) => {
        const opts = {
          name: 'CCL Reviews',
          email: customer.email,
          allowRememberMe: false,
        };
        if (panelLabel) {
          opts['panelLabel'] = panelLabel;
        } else {
          if (customer.amount_due) {
            opts['amount'] = customer.amount_due;
          } else {
            opts['panelLabel'] = 'Update';
          }
        }
        this.getStripeHandlerByAccountId(accountId).open(opts);
      });
  }

  getStripeHandlerByAccountId(accountId: string) {
    return StripeCheckout.configure({
      key: environment.stripeToken,
      image: 'https://yw48n.app.goo.gl/4v3Q',
      locale: 'auto',
      source: (source) => {
        this.updateSourceByAccountId(accountId, source);
      },
    });
  }

  updateEmail(newEmail: string): Observable<void> {
    return from(
      this.afs
        .doc(`/customers/${this.accountId}`)
        .update({ email: newEmail, state: State.Updating })
    );
  }

  updateEmailByAccountId(
    accountId: string,
    newEmail: string
  ): Observable<void> {
    return from(
      this.afs
        .doc(`/customers/${accountId}`)
        .update({ email: newEmail, state: State.Updating })
    );
  }

  updateCoupon(id: string): Observable<void> {
    return from(
      this.afs.doc(`/customers/${this.accountId}`).update({ couponId: id })
    );
  }

  validateCoupon(id: string): Observable<boolean> {
    return from(
      this.checkCoupon({ couponId: id })
        .then(() => true)
        .catch(() => false)
    );
  }

  updatePlanAndCoupon(planId: string, couponId: string): Observable<void> {
    return from(
      this.updateStripePlanAndCoupon({
        accountId: this.accountId,
        planId: planId,
        couponId: couponId,
      })
    ).pipe(mapTo(null));
  }

  updatePlanAndCouponByAccountId(
    accountId: string,
    planId: string,
    couponId: string
  ): Observable<void> {
    return from(
      this.updateStripePlanAndCoupon({
        accountId: accountId,
        planId: planId,
        couponId: couponId,
      })
    ).pipe(mapTo(null));
  }

  getInvoice(planId: string, couponId?: string): Promise<invoices.IInvoice> {
    return this.getUpcomingInvoice({ planId: planId, couponId: couponId }).then(
      (res) => res.data as invoices.IInvoice
    );
  }
}
