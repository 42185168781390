import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from '../shared/shared.module';

import { SupportComponent } from './support.component';
import { SettingsComponent } from './settings/settings.component';
import { SiteCreatorComponent } from './site-creator/site-creator.component';

import { SiteEditorComponent } from './site-editor/site-editor.component';
import { GroupCustomSenderSettingsComponent } from './group-custom-sender-settings/group-custom-sender-settings.component';
import { GroupCustomSenderEditorComponent } from './group-custom-sender-editor/group-custom-sender-editor.component';
import { LeadsEndpointComponent } from './leads-endpoint/leads-endpoint.component';
import { SupportRoutingModule } from './support-routing.module';
import { NewSiteTicketWebsiteComponent } from './new-site-ticket-website/new-site-ticket-website.component';
import { NewSiteTicketNavbarComponent } from './new-site-ticket-navbar/new-site-ticket-navbar.component';
import { NewSiteTicketNotificationsComponent } from './new-site-ticket-notifications/new-site-ticket-notifications.component';
import { NewSiteTicketBillingContactComponent } from './new-site-ticket-billing-contact/new-site-ticket-billing-contact.component';
import { NewSiteTicketSpanishChatComponent } from './new-site-ticket-spanish-chat/new-site-ticket-spanish-chat.component';
import { NewSiteTicketAnalyticsComponent } from './new-site-ticket-analytics/new-site-ticket-analytics.component';
import { NewSiteTicketCmsComponent } from './new-site-ticket-cms/new-site-ticket-cms.component';
import { NewSiteTicketResponseTimeComponent } from './new-site-ticket-response-time/new-site-ticket-response-time.component';
import { NewSiteTicketSpecialRequestComponent } from './new-site-ticket-special-request/new-site-ticket-special-request.component';
import { NewSiteTicketSubmitComponent } from './new-site-ticket-submit/new-site-ticket-submit.component';

import { IConfig, NgxMaskModule } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import { MatRadioModule } from '@angular/material/radio';
import { GroupUsersComponent } from './group-users/group-users.component';
import { GroupUserCreatorComponent } from './group-users/group-user-creator/group-user-creator.component';
import { GroupUserEditorComponent } from './group-users/group-user-editor/group-user-editor.component';
import { FacebookSitesComponent } from './facebook-sites/facebook-sites.component';
import { FacebookSetupModalComponent } from './facebook-setup-modal/facebook-setup-modal.component';
import { AutoResponderContainerComponent } from './auto-responder-container/auto-responder-container.component';
import { AutoResponderEditorComponent } from './auto-responder-editor/auto-responder-editor.component';
import { BusinessHoursEditorComponent } from './auto-responder-hours-editor/auto-responder-hours-editor.component';
import { DaySchedulerComponent } from './day-scheduler/day-scheduler.component';

@NgModule({
  imports: [
    CommonModule,
    TextMaskModule,
    SupportRoutingModule,
    MatRadioModule,
    SharedModule,
    NgxMaskModule.forRoot(options),
  ],
  declarations: [
    SupportComponent,
    SettingsComponent,
    SiteCreatorComponent,
    SiteEditorComponent,
    GroupCustomSenderSettingsComponent,
    GroupCustomSenderEditorComponent,
    LeadsEndpointComponent,
    NewSiteTicketWebsiteComponent,
    NewSiteTicketNavbarComponent,
    NewSiteTicketNotificationsComponent,
    NewSiteTicketBillingContactComponent,
    NewSiteTicketSpanishChatComponent,
    NewSiteTicketAnalyticsComponent,
    NewSiteTicketCmsComponent,
    NewSiteTicketResponseTimeComponent,
    NewSiteTicketSpecialRequestComponent,
    NewSiteTicketSubmitComponent,
    GroupUsersComponent,
    GroupUserCreatorComponent,
    GroupUserEditorComponent,
    FacebookSitesComponent,
    FacebookSetupModalComponent,
    AutoResponderContainerComponent,
    AutoResponderEditorComponent,
    BusinessHoursEditorComponent,
    DaySchedulerComponent,
  ],
  entryComponents: [
    SiteCreatorComponent,
    SiteEditorComponent,
    GroupCustomSenderEditorComponent,
    GroupUserCreatorComponent,
    GroupUserEditorComponent,
    FacebookSetupModalComponent,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        panelClass: 'theme-snackBar',
      },
    },
  ],
})
export class SupportModule {}
