import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { UserService } from 'src/app/core/user.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LiveChatGroup } from '../models/LiveChatGroup';
import { isCclGlobalAdmin } from 'src/app/core/user';

/**
 * Cache the groups that are pulled from the Platform.
 * If the user is a super admin all the groups in the Platform all pulled.
 */
@Injectable({
  providedIn: 'root',
})
export class CclGroupsStoreService {
  private baseURL = environment.cclRoot + '/drf/';

  private _groups = new ReplaySubject<LiveChatGroup[]>();

  constructor(private user: UserService, private http: HttpClient) {}

  loadGroups() {
    this.user.currentUserWithId$.subscribe((currentUser) => {
      let groupsCallback = this.groupsAllowed$;
      if (isCclGlobalAdmin(currentUser)) {
        groupsCallback = this.$allGroups;
      }
      groupsCallback.subscribe((groups) => {
        this._groups.next(groups);
      });
    });
  }

  get groupsForUser$() {
    return this._groups.asObservable();
  }

  private get groupsAllowed$(): Observable<LiveChatGroup[]> {
    return this.http.get<LiveChatGroup[]>(`${this.baseURL}groups`).pipe(
      map((groups) => {
        return groups.filter((g) => g.id !== 1);
      })
    );
  }

  private get $allGroups(): Observable<LiveChatGroup[]> {
    return this.http.get<LiveChatGroup[]>(`${this.baseURL}all-groups`).pipe(
      map((groups) => {
        return groups.filter((g) => g.id !== 1);
      })
    );
  }
}
