export enum TICKET_CATEGORIES_VALUES {
  BILLING = 'BILLING_ISSUE',
  FEATURE = 'FEATURE_REQUEST',
  INQUIRY = 'GENERAL_INQUIRY',
  CANCELLATION = 'Cancellation Request',
  INTEGRATION = 'Integration',
  NOTIFICATION = 'Notification Targets',
  DESIGN = 'Design Updates',
  ANALYTICS = 'Analytics',
  OTHER = 'Other Customer Service',
}

export enum TICKET_CATEGORIES_LABELS {
  BILLING = 'Billing and Pricing',
  FEATURE = 'Feature Request',
  CANCELLATION = 'Cancellation Request',
  INTEGRATION = 'Integration',
  NOTIFICATION = 'Notification Targets',
  DESIGN = 'Design Updates',
  ANALYTICS = 'Analytics',
  OTHER = 'Other Customer Service',
}
