export interface User {
  loggedIn?: any; // timestamp
  isSuperAdmin: boolean;
  notificationsCount?: number;
  defaultAccountId?: string;
  firstName: string;
  lastName: string;
  emailSignature?: string;
  profilePictureUrl?: string;
  cclRole?: string;
  email: string;
  isLiveChatUser?: boolean;
}

export interface IdUser extends User {
  id: string;
}

export const GLOBAL_ADMIN = 'globalAdmin';
export const GROUP_ADMIN = 'groupAdmin';
export const STANDARD_USER = 'standard';

export const AVAILABLE_ROLES = [
  {
    val: GROUP_ADMIN,
    label: 'Administrator',
  },
  {
    val: STANDARD_USER,
    label: 'Standard User',
  },
];
export const ROLES_MAPPING: { [key: string]: string } = {
  groupAdmin: 'Administrator',
  standard: 'Standard User',
};

export const isCclGlobalAdmin = (user: User) =>
  user && user.cclRole == GLOBAL_ADMIN;

export const hasAnyRole = (user: User, roles: string[]) =>
  user && roles.includes(user.cclRole);
