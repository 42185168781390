import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  shareReplay,
  mergeMap,
  map,
  flatMap,
  catchError,
} from 'rxjs/operators';
import { saveAs as importedSaveAs } from 'file-saver';

import { LiveChatSite } from './live-chat-site';
import { LiveChatLead } from './live-chat-lead';
import { LiveChatParams, Params } from './live-chat-params';

import { environment } from '../../../environments/environment';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
const querystring = require('querystring');
@Injectable({
  providedIn: 'root',
})
export class LiveChatService {
  private baseURL = environment.cclRoot + '/drf/';
  _sites$: Observable<any[]>;

  constructor(
    private http: HttpClient,
    private groupService: CclSelectedGroupService
  ) {}

  get groups$(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseURL}groups`).pipe(
      map((groups) => {
        return groups.filter((g) => g.id !== 1);
      })
    );
  }

  get allGroups$(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseURL}all-groups`).pipe(
      map((groups) => {
        return groups.filter((g) => g.id !== 1);
      })
    );
  }

  getInvoiceGroupsByClient(clientUref: string): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseURL}client/${clientUref}/invoice-groups`
    );
  }

  getUsersFromGroup(groupId: string): Observable<any[]> {
    return this.http.get<any>(`${this.baseURL}group/${groupId}/users`);
  }

  get groupsSummary$(): Observable<
    {
      id: number;
      clientName: string;
      groupName: string;
      invoiceGroup: string;
    }[]
  > {
    return this.http.get<any[]>(`${this.baseURL}groups_summary`).pipe(
      map((groups) => {
        return groups.filter((g) => g.id !== 1);
      })
    );
  }

  getStripeDataFromGroup(groupId: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}stripe_detail_from_group/${groupId}`
    );
  }

  getGroupDetail(groupId: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}group_detail/${groupId}`);
  }

  getInvoiceOwnerDetail(groupId: number): Observable<any> {
    return this.http.get<any>(`${this.baseURL}invoice_owner_detail/${groupId}`);
  }

  updateInvoiceOwnerDetail(
    groupId: number,
    billingContactName: string,
    contactEmail: string,
    additionalEmails: string[],
    emailsToRemove: string[]
  ): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}invoice_owner_detail/${groupId}/update`,
      {
        billingContactName,
        contactEmail,
        additionalEmails,
        emailsToRemove,
      }
    );
  }

  getInvoiceGroupEmails(invoiceGroupUref: string): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseURL}invoice_group/${invoiceGroupUref}/email-targets`
    );
  }

  createInvoiceGroupEmail(
    invoiceGroupUref: string,
    email: string
  ): Observable<any[]> {
    return this.http.post<any>(
      `${this.baseURL}invoice_group/${invoiceGroupUref}/email-targets/create`,
      { email }
    );
  }

  deleteInvoiceGroupEmail(
    invoiceGroupUref: string,
    emailId: number
  ): Observable<any[]> {
    return this.http.delete<any>(
      `${this.baseURL}invoice_group/${invoiceGroupUref}/email-targets/delete/${emailId}`
    );
  }

  get sites$(): Observable<LiveChatSite[]> {
    if (!this._sites$) {
      this._sites$ = this.groupService.selectedId.pipe(
        flatMap((groupId) => {
          if (groupId == 1) {
            return of([]);
          }
          return this.http
            .get<LiveChatSite[]>(
              `${this.baseURL}sites_by_group?groupId=${groupId}`
            )
            .pipe(shareReplay(1))
            .pipe(
              map((sites) => {
                return sites as LiveChatSite[];
              })
            );
        })
      );
    }
    return this._sites$;
  }

  get(suffix: string, args: Params): Observable<any> {
    const params = Object.assign({}, args);
    if (params['sites']) {
      delete params['sites'];
    }
    return this.http.get<any>(`${this.baseURL}${suffix}`, { params });
  }

  post(suffix: string, args: Params): Observable<any> {
    if (args['sites']) {
      delete args['sites'];
    }
    const { site__in, ...rest } = args as LiveChatParams;
    return this.http.post<any>(
      `${this.baseURL}${suffix}`,
      { site__in },
      { params: rest }
    );
  }

  getSitesByGroupWithNotifications(
    groupId: number
  ): Observable<LiveChatSite[]> {
    return this.http
      .get<LiveChatSite[]>(
        `${this.baseURL}sites_by_group_with_notifications?groupId=${groupId}`
      )
      .pipe(
        map((sites) => {
          return sites as LiveChatSite[];
        })
      );
  }

  getSiteCounts(args: Params): Observable<any> {
    return this.get('site_counts', args);
  }

  getDateCounts(args: Params): Observable<any[]> {
    return this.post('date_counts', args);
  }

  getSiteEmails(siteIds: number[], groupId: number): Observable<any[]> {
    return this.get('site_emails', {
      groupId: groupId,
      site__in: siteIds.join(),
    }).pipe(shareReplay(1));
  }

  getChats(
    args: Params
  ): Observable<{ count: number; results: LiveChatLead[] }> {
    const request = Object.assign({}, args);
    if (request['sites']) {
      request['sites'] = null;
    }
    const data = request.site__in;
    delete request.site__in;
    const params = querystring.encode(request);
    return this.http
      .post<{ count: number; results: LiveChatLead[] }>(
        `${this.baseURL}chats?${params}`,
        {
          site__in: data,
        }
      )
      .pipe(
        map((leads) => leads),
        catchError((_) => of({ count: 0, results: [] }))
      );
  }

  getLeads(
    args: Params
  ): Observable<{ count: number; results: LiveChatLead[] }> {
    const request = Object.assign({}, args);
    if (request['sites']) {
      request['sites'] = null;
    }
    return this.http.get<{ count: number; results: LiveChatLead[] }>(
      `${this.baseURL}leads`,
      {
        params: request,
      }
    );
  }

  getTranscript(sessionUuid: string): Observable<any> {
    return this.http.get(`${this.baseURL}chat/${sessionUuid}/transcript`, {
      responseType: 'text',
    });
  }

  emailTranscript(name: string, html: string, email: string): Observable<any> {
    return this.http.post<any>(`${this.baseURL}chats/email-transcript`, {
      name,
      html,
      email,
    });
  }

  getLeadDetails(uuid: string): Observable<any> {
    return this.http.get(`${this.baseURL}lead/${uuid}/details`);
  }

  getLead(uuid: string): Observable<any> {
    return this.http.get(`${this.baseURL}lead/${uuid}`, {
      responseType: 'text',
    });
  }

  getDeviceCounts(args: Params): Observable<any> {
    return this.get('device_counts', args);
  }

  getReferralSourceCounts(args: Params): Observable<any> {
    return this.get('referral_source_counts', args);
  }

  saveCsv(groupId: number, args: Params): Observable<void> {
    return this.allGroups$.pipe(
      mergeMap((groups) => {
        const clientGroup = groups.find(
          (g) => !!g.client_uref && g.id == groupId
        );
        const { site__in, sites, ...rest } = args;
        return this.http.post(
          `${this.baseURL}csv`,
          { site__in },
          {
            observe: 'response',
            responseType: 'blob',
            params: { client: clientGroup.client_uref, ...rest },
          }
        );
      }),
      map((res) => {
        const contentDisposition = res.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        const fileName = ((matches && matches[1].replace(/"/g, "")) || 'download').trim();
        importedSaveAs(res.body, fileName);
        return null;
      })
    );
  }

  rejectChatLead(
    chatUuid: string,
    reason: string,
    email: string
  ): Observable<any> {
    return this.http.post(`${this.baseURL}lead/reject`, {
      uuid: chatUuid,
      reason: reason,
      email: email,
    });
  }

  rejectChatLeads(
    leadsUuid: string[],
    sessionsUuid: string[],
    emails: string[],
    reason: string
  ): Observable<any> {
    return this.http.post(`${this.baseURL}lead/reject_multiple`, {
      leadsUuid,
      sessionsUuid,
      emails,
      reason,
    });
  }

  leadsSummaryPdf(
    startDate: string,
    endDate: string,
    targetType: string,
    targetUref: string
  ): Observable<any> {
    return this.http.post(`${environment.cclRoot}/report/lead/summary`, {
      onlyLeads: true,
      start: startDate,
      end: endDate,
      target_type: targetType,
      target_uref: targetUref,
      tz: 'bill',
    });
  }

  getReportChatSummaryDetail(taskId: string): Observable<any> {
    return this.http.get(
      `${environment.cclRoot}/report/lead/summary/${taskId}`
    );
  }

  getInvoicesFromGroup(
    groupId: number,
    startDate: string,
    endDate: string
  ): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/invoice_owner/${groupId}/invoices`,
      {
        params: { startDate, endDate },
      }
    );
  }

  // /report/invoice/cgz4Mi
}
