import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from '@angular/fire/firestore';
import { Observable, from, forkJoin } from 'rxjs';
import { map, mergeMap, mapTo, filter, take } from 'rxjs/operators';

import { Notification, IdNotification } from './notification';
import { UserService } from './user.service';
import firebase from '@firebase/app';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  filteredCol$: Observable<AngularFirestoreCollection<Notification>>;
  col$: Observable<AngularFirestoreCollection<Notification>>;

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private userService: UserService
  ) {
    this.filteredCol$ = this.userService.doc$.pipe(
      map((doc) =>
        doc.collection<Notification>('notifications', (ref) =>
          ref.where('isRead', '==', false).orderBy('datetimeCreated', 'asc')
        )
      )
    );

    this.col$ = this.userService.doc$.pipe(
      map((doc) => doc.collection<Notification>('notifications'))
    );
  }

  async getAll$(): Promise<Observable<IdNotification[]>> {
    const userId = (await this.afAuth.currentUser).uid;

    return this.afs
      .collection(`users/${userId}/notifications`, (ref) =>
        ref.orderBy('datetimeCreated', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((snap) => {
          return snap.map((doc) => {
            return {
              id: doc.payload.doc.id,
              ...(doc.payload.doc.data() as any),
            } as IdNotification;
          });
        })
      );
  }

  async markNotificationsAsRead(): Promise<Observable<any>> {
    const userId = (await this.afAuth.currentUser).uid;

    return from(
      this.afs.doc(`users/${userId}`).update({
        notificationsCount: 0,
      })
    );
  }

  /*
   * Marks all notifications as read.
   * Used to prevent latent notifications.
   */
  markAllAsRead(): Observable<void> {
    return this.filteredCol$.pipe(
      mergeMap((col) => col.snapshotChanges()),
      take(1),
      map((actions) => {
        if (!(actions && actions.length)) {
          return [];
        }
        return actions.map((action) => action.payload.doc.ref);
      }),
      mergeMap((refs) =>
        forkJoin(refs.map((ref) => from(ref.update({ isRead: true }))))
      ),
      mapTo(null)
    );
  }

  get latestDoc$(): Observable<IdNotification | null> {
    return this.filteredCol$.pipe(
      mergeMap((col) => col.snapshotChanges()),
      filter((actions) => actions && actions.length > 0),
      map((actions) => {
        const a = actions[0].payload.doc;
        return { id: a.id, ...a.data() } as IdNotification;
      })
    );
  }

  markAsRead(id: string): Observable<void> {
    return this.col$.pipe(
      mergeMap((col) => from(col.doc(id).update({ isRead: true })))
    );
  }

  deleteSingleNotification(id: string): Observable<void> {
    return this.col$.pipe(mergeMap((col) => from(col.doc(id).delete())));
  }

  deleteAllNotifications(): Observable<any> {
    const getContactInfo = firebase
      .functions()
      .httpsCallable('deleteAllNotifications');
    return from(getContactInfo());
  }
}
