import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { AutoResponseDaySchedule } from '../models/auto-responder-day-schedule-range';

/**
 * This component represents a single day from the auto-responder hours,
 * on each day you can configure multiple time spans.
 */
@Component({
  selector: 'app-day-scheduler',
  templateUrl: './day-scheduler.component.html',
  styleUrls: ['./day-scheduler.component.scss'],
})
export class DaySchedulerComponent implements OnInit {
  @Input() day: number;
  @Input() data: AutoResponseDaySchedule;

  @Output() copyToBottomWeekDays = new EventEmitter<number>();

  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  /**
   * Returns the name of the name from the ``days`` array based on the day
   * index.
   *
   * TODO: Move this ``days`` array to another place to be a shared object or
   * use a library to achieve this goal.
   */
  get dayName() {
    return this.days[this.day];
  }

  constructor() {}

  /**
   * Initializes component variables.
   */
  ngOnInit() {
    if (!this.data[this.day]) {
      this.data[this.day] = [];
    }
  }

  /**
   * Add an extra empty column to add time data.
   */
  onAddTimeRangeClick() {
    this.data[this.day].push({});
  }

  /**
   * Removes the time data from the day corresponding to the given column.
   */
  onRemoveTimeRangeClick(row: any) {
    this.data[this.day] = this.data[this.day].filter((r) => r !== row);
  }

  /**
   * Flag indicating if a row is valid to be shown. To be valid a row must have a
   * start and end time, and end time must be greater than start time.
   */
  rowIsInvalid(row) {
    if (!row.startTime || !row.endTime) {
      return false;
    }

    const hasAmOrPm =
      row.startTime.toUpperCase().includes('AM') ||
      row.startTime.toUpperCase().includes('PM');
    const timeFormat = hasAmOrPm ? 'HH:mm aa' : 'HH:mm';

    const start = moment(row.startTime, timeFormat);
    const end = moment(row.endTime, timeFormat);

    this.data.isInvalid = end <= start;
    return end <= start;
  }
}
