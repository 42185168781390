import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, mapTo, take, tap } from 'rxjs/operators';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { AccountService } from './core/account.service';
import { SpinnerService } from './core/spinner.service';

import { InformationType } from './core/support-ticket';
import { SupportTicketService } from './core/support-ticket.service';

@Injectable()
export class ReviewsGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private stService: SupportTicketService,
    private accountService: AccountService,
    private spinnerService: SpinnerService,
    private router: Router
  ) {}

  /**
   * Allows the navigation to the given route only if there is an authenticated
   * user and it is active.
   */
  canActivate(_: ActivatedRouteSnapshot): Observable<boolean> {
    this.spinnerService.setState(true);

    return this.userService.user$.pipe(
      mergeMap((user) => {
        // if they're a reviews user, activate
        if (user.defaultAccountId) {
          return this.accountService.isActive$.pipe(take(1));
        }
        // if it's the base path, reroute
        if (this.router.url === '/') {
          this.router.navigate(['/live-chat']);
          return of(false);
        }
        // if not, but they're a live chat user
        if (user.isLiveChatUser) {
          // ask if they want more information on reviews
          return this.stService
            .openInformationRequestDialog(InformationType.Reviews)
            .pipe(
              mapTo(false) // deny access to the page
            );
        }

        // Navigate to the login page
        this.authService.kickOut(this.router.url);
        return of(false);
      }),
      catchError((err) => {
        console.log(err);
        console.log('error');
        console.log('kicking out');
        this.authService.kickOut(this.router.url);
        return of(false);
      }),
      tap(() => this.spinnerService.setState(false))
    );
  }

  /**
   * Uses the same 'User is active?' to allow navigation to child routes.
   */
  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }
}
