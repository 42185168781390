import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewSiteTicket, NewSiteTicketProgress } from '../NewSiteTicketModel';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-site-ticket-response-time',
  templateUrl: './new-site-ticket-response-time.component.html',
  styleUrls: ['./new-site-ticket-response-time.component.scss'],
})
export class NewSiteTicketResponseTimeComponent implements OnInit {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;
  @Output() changeCurrentStep = new EventEmitter<string>();
  choices = [
    'Usually someone will get back to you in a few minutes, but it is almost never longer than 24 hours.',
    'During business hours, someone usually gets back to you right away, but it is almost never longer than one business day.',
    'Someone from the office will get back to you in the next 2-3 business days.',
    'They will contact you as soon as possible.',
  ];

  isLoading = true;
  isCompleted = false;
  choice: string = null;

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.isCompleted = this.progress.responseTimeCompleted === true;
    this.choice = this.model.responseTimeAnswer;
    window.setTimeout(() => (this.isLoading = false), 500);
  }

  /**
   * When the "form" is submitted, validates that a choice was choosen, stores it
   * and continues to the next step.
   */
  onSubmit() {
    if (!this.choice) {
      this.snackBar.open('Please choose an option', 'Ok');
      return;
    }
    this.isLoading = true;
    this.model.responseTimeAnswer = this.choice;
    this.progress.responseTimeCompleted = true;
    this.changeCurrentStep.emit('specialRequest');
  }
}
