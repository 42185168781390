import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

/**
 * This component is used to show a user, lead, contact, etc; avatar pic. It
 * will use the name to generate a one or two letters avatar pic with the
 * initials of the first and second name.
 */
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() size: number = 24;
  @Input() name: string;

  numberOfStyles = 5;

  letters = '';
  styleIndex = null;
  useAnonymous = false;

  constructor() {}

  ngOnInit(): void {}

  /**
   * If the name changes get the letters that are going to be used as avatar
   * pic.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name) {
      this.getLetters();
    }
  }

  /**
   * Uses the given name to extract the first character of the first and second
   * name, capitalize them and use them as the avatar pic.
   */
  getLetters() {
    this.useAnonymous = false;
    if (this.name) {
      let intValue = 0;
      const words = this.name.split(' ');
      const firstChar = words[0][0].trim().toUpperCase();
      intValue += firstChar.charCodeAt(0);
      this.letters = firstChar;
      if (words.length > 1 && words[1][0]) {
        const secondChar = words[1][0].trim().toUpperCase();
        this.letters += secondChar;
        intValue += secondChar.charCodeAt(0);
      }

      this.styleIndex = (intValue % this.numberOfStyles) + 1;
    } else {
      this.useAnonymous = true;
    }
  }
}
