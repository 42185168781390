import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LiveChatAuthService {
  private baseURL = environment.cclRoot + '/drf/';

  constructor(private http: HttpClient) {}

  getCclToken(firebaseClientToken: string): Observable<{ token: string }> {
    const encoded = encodeURI(firebaseClientToken);
    return this.http.get<{ token: string }>(
      `${this.baseURL}/access_token/${encoded}`
    );
  }
}
