import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Service created to show success and error SnackBars. Is useful to have
 * this logic separated in case we want to change UI library on the
 * future and use other SnackBar and not the Material's one.
 */
@Injectable({
  providedIn: 'root',
})
export class Notify {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * Opens a success MatSnackBar with the given message and action.
   */
  success(message: string, action = 'Ok') {
    this.snackBar.open(message, action);
  }

  /**
   * Opens an error MatSnackBar with the given message and action.
   */
  error(message: string, action = 'Ok') {
    this.snackBar.open(message, action, {
      panelClass: ['theme-snackBar', 'theme-snackBar-error'],
    });
  }
}
