import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable, from, of } from 'rxjs';
import {
  map,
  mergeMap,
  filter,
  catchError,
  take,
  switchMap,
  mapTo,
} from 'rxjs/operators';
import firebase from 'firebase';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../environments/environment';
import { AuthenticationEvent } from './authentication-event';
import { LiveChatAuthService } from './live-chat-auth.service';
import { WhiteLabelService } from './services/white-label.service';

@Injectable()
export class AuthService {
  public user$: Observable<firebase.User>;
  public uid$: Observable<string>;
  private baseUrl = environment.cclRoot + '/drf/';
  private eventCollection: AngularFirestoreCollection<AuthenticationEvent>;

  constructor(
    private cclAuthService: LiveChatAuthService,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private http: HttpClient,
    private jwt: JwtHelperService,
    private whiteLabelService: WhiteLabelService
  ) {
    // Filter out null users to prevent null key errors
    this.user$ = this.afAuth.authState.pipe(filter((user) => user !== null));
    this.uid$ = this.user$.pipe(map((user) => user.uid));

    this.eventCollection = this.afs.collection<AuthenticationEvent>(
      'authentication-events'
    );
  }

  /*
   * Navigate to the login page
   */
  kickOut(redirectUrl?: string): void {
    localStorage.removeItem('access_token');
    let url = this.whiteLabelService.whiteLabel.authUrl;
    if (redirectUrl) {
      url += '?redirectUrl=' + encodeURI(window.location.href);
    }
    window.open(url, '_self', 'location=yes');
  }

  signOut() {
    from(this.afAuth.signOut()).subscribe(() => this.kickOut());
  }

  updatePassword(newPassword: string): Observable<any> {
    return this.user$.pipe(
      mergeMap((user) => from(user.updatePassword(newPassword)))
    );
  }

  checkPassword(email: string, password: string): Promise<boolean> {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => true)
      .catch(() => false);
  }

  get isAuthenticatedForLiveChat(): Observable<boolean> {
    const token = localStorage.getItem('access_token') || null;
    if (!this.jwt.isTokenExpired(token)) {
      return of(true);
    }
    return this.afAuth.idTokenResult.pipe(
      take(1),
      switchMap((idToken) => {
        return this.cclAuthService.getCclToken(idToken.token).pipe(
          map((result) => {
            localStorage.setItem('access_token', result.token);
          })
        );
      }),
      mapTo(true),
      catchError((e) => {
        console.log(e);
        return of(false);
      })
    );
  }

  refreshLiveChatToken(): void {
    const token = localStorage.getItem('access_token') || null;

    // only refresh token if it expires in an hour or less
    if (!this.jwt.isTokenExpired(token, 3600)) {
      return null;
    }

    this.http
      .post(this.baseUrl + 'refresh', { token: token })
      .subscribe((res) => localStorage.setItem('access_token', res['token']));
  }

  createEvent(): void {
    this.uid$.pipe(take(1)).subscribe((uid) =>
      this.eventCollection.add({
        datetimeCreated: firebase.firestore.FieldValue.serverTimestamp(),
        user: uid,
      })
    );
  }
}
