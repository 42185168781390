import { Component, OnInit, Inject } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LeadsEndpointService } from '../services/leads-endpoint.service';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-leads-endpoint',
  templateUrl: './leads-endpoint.component.html',
  styleUrls: ['./leads-endpoint.component.scss'],
})
export class LeadsEndpointComponent implements OnInit {
  isLoading = true;
  groupId: number;
  cclGroup: any;
  showCopyMsg = false;

  constructor(
    public cclGroupService: CclSelectedGroupService,
    private leadsEnpointService: LeadsEndpointService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /**
   * Gets current group's information from a service.
   */
  ngOnInit() {
    this.cclGroupService.cclGroupWithMeta$
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((result) => {
        if (!result) return;

        this.groupId = result.id;
        this.cclGroup = result.group;
      });
  }

  /**
   * Creates an API key and leads endpoint URL for the current group.
   */
  activateLeadEndpoint() {
    this.isLoading = true;
    this.leadsEnpointService
      .createEndpoint(this.groupId)
      .then(() => (this.isLoading = false));
  }

  /**
   * Deletes API key and leads endpoint URL of the current group.
   */
  deactivateLeadEndpoint() {
    this.isLoading = true;
    this.leadsEnpointService
      .deleteEndpoint(this.groupId)
      .then(() => (this.isLoading = false));
  }

  /**
   * Copies the content of the leads edpoint URL textarea to the Operating
   * System clipboard and shows an informative message.
   */
  copyLinkToClipboard() {
    const selBox = this.document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.cclGroup.leadsEndpointUrl;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);

    this.showCopyMsg = true;
    setTimeout(() => (this.showCopyMsg = false), 600);
  }
}
