import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/core/user.service';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { User } from 'functions/src/models/user';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/alert-dialog/alert-dialog.component';
import { Notify } from 'src/app/shared/services/notify.service';
import { GroupUsersService, UserGroup } from '../service/group-users.service';
import { memoize } from 'src/app/shared/memoize-decorator';
import {
  hasAnyRole,
  GROUP_ADMIN,
  GLOBAL_ADMIN,
  ROLES_MAPPING,
} from 'src/app/core/user';
import { GroupUserCreatorComponent } from './group-user-creator/group-user-creator.component';
import { GroupUserEditorComponent } from './group-user-editor/group-user-editor.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-group-users',
  templateUrl: './group-users.component.html',
  styleUrls: ['./group-users.component.scss'],
})
export class GroupUsersComponent implements OnInit, OnDestroy {
  isLoading = true;
  users: UserGroup[];
  currentUser: User;
  displayedColumns = ['name', 'email', 'role', 'status', 'action'];
  groupId: number;

  groupSub: Subscription;
  usersSub: Subscription;
  currentUserSub: Subscription;

  roles = ROLES_MAPPING;

  constructor(
    private groupUsersService: GroupUsersService,
    private groupService: CclSelectedGroupService,
    private userService: UserService,
    private dialog: MatDialog,
    private notify: Notify
  ) {}

  /**
   * Gets the current user and the current group with its users.
   */
  ngOnInit(): void {
    this.currentUserSub = this.userService.currentUserWithId$.subscribe(
      (currentUser) => {
        this.currentUser = currentUser;
        this.getSelectedGroup();
      }
    );
  }

  ngOnDestroy() {}

  /**
   * Gets the current group to fetch all its users.
   */
  getSelectedGroup() {
    this.groupSub = this.groupService.selectedId.subscribe((selectedId) => {
      this.groupId = selectedId;
      if (this.groupId) {
        this.getUsers();
      }
    });
  }

  /**
   * Flag indicating wether the user has global or group admin role, and so
   * invite users permission.
   */
  @memoize()
  canInviteUsers(user: User) {
    return hasAnyRole(user, [GROUP_ADMIN, GLOBAL_ADMIN]);
  }

  /**
   * Flag indicating wether the user has global or group admin role, and so
   * update and delete users permission.
   */
  @memoize()
  canUpdateAndDeleteUsers(user: User) {
    return hasAnyRole(user, [GROUP_ADMIN, GLOBAL_ADMIN]);
  }

  /**
   * Gets the information of all the users of CCL from the current group.
   */
  getUsers() {
    this.isLoading = true;
    if (this.usersSub) {
      this.usersSub.unsubscribe();
    }
    this.usersSub = this.groupUsersService
      .getUsersFromGroup(this.groupId.toString())
      .subscribe((users) => {
        this.users = users;
        this.isLoading = false;
      });
  }

  /**
   * Opens the GroupUserCreator modal to invite an user and then refetchs
   * the user data.
   */
  inviteUser() {
    this.dialog
      .open(GroupUserCreatorComponent)
      .afterClosed()
      .subscribe(() => this.getUsers());
  }

  /**
   * Opens the GroupUserEditor modal to edit the given user and then refetchs
   * the user data.
   */
  editUser(user: User) {
    const dialogRef = this.dialog.open(GroupUserEditorComponent, {
      data: { user },
    });

    dialogRef.afterClosed().subscribe((_) => {
      this.getUsers();
    });
  }

  /**
   * After a confirmation through a confirmation modal, removes the given user
   * from the current group.
   */
  removeUserFromGroup(user: User) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        title: 'Remove user from group',
        content: 'Are you sure? This cannot be undone.',
        buttons: [
          {
            text: 'Cancel',
            value: false,
          },
          {
            text: 'Yes, delete',
            value: true,
            color: 'warn',
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.isLoading = true;
        this.userService
          .removeUserFromGroup(this.groupId, user.email)
          .subscribe(
            (_) => {
              this.notify.success('User deleted succesfully');
              this.getUsers();
            },
            (_) => {
              this.notify.error('An error has ocurred, please try again later');
            }
          );
      }
    });
  }
}
