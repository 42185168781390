import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, mapTo, take, switchMap } from 'rxjs/operators';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../../core/auth.service';
import { UserService } from '../../core/user.service';
import { SpinnerService } from '../../core/spinner.service';

import { InformationType } from '../../core/support-ticket';
import { SupportTicketService } from '../../core/support-ticket.service';
import { CclGroupsStoreService } from '../services/ccl-groups-store.service';
import firebase from 'firebase';

@Injectable()
export class LiveChatGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private stService: SupportTicketService,
    private groupsStore: CclGroupsStoreService,
    private router: Router // private authGuard: AuthGuard
  ) {}

  canActivate(): Observable<boolean> {
    this.spinnerService.setState(true);
    console.log('LC: Iniciando can activate');
    return this.userService.user$.pipe(
      take(1),
      tap(() => this.spinnerService.setState(false)),
      switchMap((user) => {
        // if they're a live chat user, activate
        if (user.isLiveChatUser) {
          return this.authService.isAuthenticatedForLiveChat.pipe(
            tap((isAuthenticated) => {
              if (isAuthenticated) {
                this.groupsStore.loadGroups();
                this.authService.refreshLiveChatToken();
                this.authService.uid$.subscribe((userId) => {
                  this.userService.updateUser(userId, {
                    loggedIn: firebase.firestore.Timestamp.now(),
                  });
                });
              }
            })
          );
        }

        // if not, but they're a reviews user
        if (user.defaultAccountId) {
          // ask if they want more information on live chat
          return this.stService
            .openInformationRequestDialog(InformationType.LiveChat)
            .pipe(
              mapTo(false) // deny access to the page
            );
        }

        // Navigate to the login page
        this.authService.kickOut(this.router.url);
        return of(false);
      }),
      catchError((err) => {
        console.log(err);
        console.log('error');
        console.log('kicking out');
        this.authService.kickOut(this.router.url);
        return of(false);
      })
    );
  }
}
