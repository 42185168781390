import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, combineLatest } from 'rxjs';
import { mergeMap, map, shareReplay, mapTo, take } from 'rxjs/operators';
import firebase from 'firebase';

import { AuthService } from './auth.service';
import { Agreement } from './agreement';
import { BillingContract } from './billing-contract';

@Injectable({
  providedIn: 'root',
})
export class BillingContractService {
  collectionName = 'billing-contracts';
  latestAgreement$: Observable<Agreement>;

  constructor(private afs: AngularFirestore, private authService: AuthService) {
    this.latestAgreement$ = this.afs
      .collection<Agreement>('agreements', (ref) =>
        ref.orderBy('datetimeCreated', 'desc').limit(1)
      )
      .valueChanges()
      .pipe(
        map((agreements) => agreements[0]),
        shareReplay(1)
      );
  }

  /*
   * Creates a new contract. The ID is the user's ID.
   * The text is copied from the latest agreement.
   * Returns promise; this *shouldn't* be a stream.
   */
  create(): Observable<void> {
    return combineLatest(this.authService.uid$, this.latestAgreement$).pipe(
      take(1),
      mergeMap(([uid, agreement]) =>
        this.afs.collection<BillingContract>(this.collectionName).add({
          terms: agreement.terms,
          privacyPolicy: agreement.privacyPolicy,
          datetimeCreated: firebase.firestore.FieldValue.serverTimestamp(),
          user: uid,
        })
      ),
      mapTo(null)
    );
  }
}
