export enum SUPPORT_TICKET_TYPE {
  NEW_SITE = 'new-site-ticket',
  NEW_SITE_V2 = 'new-site-ticket-v2',
  EXISTING_SITE = 'existing-site-ticket',
  INFORMATION_REQUEST = 'information-request',
  SERVICE = 'service-ticket',
  ONBOARDING = 'onboarding-ticket',
}

export enum SUPPORT_TICKET_TYPE_LABELS {
  'new-site-ticket-v2' = 'New Site Request',
  'service-ticket' = 'Service Ticket',
}
