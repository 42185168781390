import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable, from, combineLatest, of } from 'rxjs';
import { map, mergeMap, shareReplay, tap, take } from 'rxjs/operators';

import { IdUser } from './user';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { Account } from './account';
import { AccountUser, IdAccountUser, Status } from './account-user';

declare var gapi;

@Injectable()
export class AccountService {
  accountCollection: AngularFirestoreCollection<Account>;
  collectionName = 'accounts';
  public accountUserDoc$: Observable<AngularFirestoreDocument<AccountUser>>;
  public accountUser$: Observable<AccountUser>;
  _accountId: string;

  private _account$: Observable<Account>;
  private _isActive$: Observable<boolean>;
  private _idAccountUsers$: Observable<Array<IdAccountUser>>;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private userService: UserService,
    private authService: AuthService
  ) {
    this.accountCollection = this.afs.collection<Account>(this.collectionName);
    this.accountUserDoc$ = this.userService.currentUserWithId$.pipe(
      map((user) =>
        this.afs.doc<AccountUser>(
          `accounts/${user.defaultAccountId}/users/${user.id}`
        )
      )
    );

    this.accountUser$ = this.accountUserDoc$.pipe(
      mergeMap((doc) => doc.valueChanges())
    );
  }

  get accountId$(): Observable<string> {
    if (this._accountId) {
      return of(this._accountId);
    }
    return this.userService.user$.pipe(
      map((user) => user.defaultAccountId),
      tap((aid) => (this._accountId = aid))
    );
  }

  get doc$(): Observable<AngularFirestoreDocument<Account>> {
    return this.accountId$.pipe(
      map((aid) => this.accountCollection.doc<Account>(aid))
    );
  }

  getDocById$(accountId): Observable<AngularFirestoreDocument<Account>> {
    return of(this.afs.doc<Account>(`accounts/${accountId}`));
  }

  get account$(): Observable<Account> {
    if (!this._account$) {
      this._account$ = this.doc$.pipe(
        mergeMap((doc) => doc.valueChanges()),
        shareReplay(1)
      );
    }
    return this._account$;
  }

  getAccountById(accountId: string): Observable<Account> {
    return this.accountCollection.doc<Account>(accountId).valueChanges();
  }

  get isAdmin$(): Observable<boolean> {
    return of(true);
  }

  get isActive$(): Observable<boolean> {
    if (!this._isActive$) {
      this._isActive$ = combineLatest(this.doc$, this.authService.uid$).pipe(
        map(([accountDoc, uid]) => accountDoc.collection('users').doc(uid)),
        mergeMap((userDoc) => userDoc.valueChanges()),
        map((user) => user['status'] === Status.Active),
        shareReplay(1)
      );
    }
    return this._isActive$;
  }

  get isSetUp$(): Observable<boolean> {
    return this.account$.pipe(map((account) => account.isSetUp));
  }

  get idAccountUsers$(): Observable<Array<IdAccountUser>> {
    if (!this._idAccountUsers$) {
      this._idAccountUsers$ = this.doc$.pipe(
        mergeMap((accountDoc) =>
          accountDoc.collection<AccountUser>('users').snapshotChanges()
        ),
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data } as IdAccountUser;
          })
        ),
        shareReplay(1)
      );
    }
    return this._idAccountUsers$;
  }

  getIdAccountUsersByAccountId(
    accountId: string
  ): Observable<Array<IdAccountUser>> {
    const sub = this.getDocById$(accountId).pipe(
      mergeMap((accountDoc) =>
        accountDoc.collection<AccountUser>('users').snapshotChanges()
      ),
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data } as IdAccountUser;
        })
      ),
      shareReplay(1)
    );
    return sub;
  }

  get idUsers$(): Observable<Array<IdUser>> {
    return this.idAccountUsers$.pipe(
      map((actions) => actions.map((a) => a.id)),
      mergeMap((ids) =>
        combineLatest(...ids.map((id) => this.userService.getIdUser$(id)))
      )
    );
  }

  getIdUsersByAccountId(accountId: string): Observable<Array<IdUser>> {
    return this.getIdAccountUsersByAccountId(accountId).pipe(
      map((actions) => {
        return actions.map((a) => a.id);
      }),
      mergeMap((ids) => {
        if (!ids.length) {
          return of([]);
        }
        return combineLatest(
          ...ids.map((id) => this.userService.getIdUser$(id))
        );
      })
    );
  }

  getAccessToken(site: string): Observable<string | null> {
    return this.account$.pipe(
      map((account) => (account.tokens ? account.tokens[site] || null : null))
    );
  }

  getAccessTokenByAccountId(
    site: string,
    accountId: string
  ): Observable<string | null> {
    return this.getAccountById(accountId).pipe(
      map((account) => (account.tokens ? account.tokens[site] || null : null))
    );
  }

  setAccessToken(site: string, token: string): Observable<void> {
    const updates = {};
    updates['tokens.' + site] = token;
    return this.doc$.pipe(
      mergeMap((accountDoc) => from(accountDoc.update(updates)))
    );
  }

  updateField(field: string, value: any): Observable<void> {
    const args = {};
    args[field] = value;
    return this.doc$.pipe(
      mergeMap((accountDoc) => from(accountDoc.update(args)))
    );
  }

  updateFieldByAccountId(
    accountId: string,
    field: string,
    value: any
  ): Observable<void> {
    const args = {};
    args[field] = value;
    return this.getDocById$(accountId).pipe(
      mergeMap((accountDoc) => from(accountDoc.update(args)))
    );
  }

  linkWithGoogle(): Observable<void> {
    const promise = Promise.resolve(gapi.auth2.getAuthInstance().signIn());

    return from(promise);
  }

  getUserDoc(id: string): Observable<AngularFirestoreDocument<AccountUser>> {
    return this.doc$.pipe(
      map((doc) => doc.collection<AccountUser>('users').doc(id))
    );
  }

  getUserDocByAccountId(
    accountId: string,
    userId: string
  ): Observable<AngularFirestoreDocument<AccountUser>> {
    return this.getDocById$(accountId).pipe(
      map((doc) => doc.collection<AccountUser>('users').doc(userId))
    );
  }

  setUserStatus(
    accountId: string,
    userId: string,
    status: Status
  ): Observable<void> {
    return this.getUserDocByAccountId(accountId, userId).pipe(
      mergeMap((doc) => doc.update({ status: status }))
    );
  }

  setUserAsSetUp(): Observable<void> {
    return this.accountUserDoc$.pipe(
      take(1),
      mergeMap((doc) => doc.update({ isSetUp: true }))
    );
  }
}
