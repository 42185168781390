import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NewSiteTicketProgress, NewSiteTicket } from '../NewSiteTicketModel';

@Component({
  selector: 'app-new-site-ticket-navbar',
  templateUrl: './new-site-ticket-navbar.component.html',
  styleUrls: ['./new-site-ticket-navbar.component.scss'],
})
export class NewSiteTicketNavbarComponent implements OnInit {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;
  @Input() currentStep: string;

  @Output() onStepClick = new EventEmitter<string>();

  hasSetupDone = false;

  constructor() {}

  ngOnInit() {}

  /**
   * Emits the event that will navigate to the given step if it is
   * unlocked.
   */
  onClickNavbarItem(step: string, locked: boolean) {
    if (!locked) {
      this.onStepClick.emit(step);
    }
  }

  /*
    Getters of the progress of each step of the form that are shown
    it the navbar. This will be used to check if it is unlocked to
    navigate into each step.
   */
  get websiteCompleted() {
    return this.progress.websiteCompleted;
  }
  get notificationsCompleted() {
    return this.progress.notificationsCompleted;
  }
  get billingContactCompleted() {
    return this.progress.billingContactCompleted;
  }
  get spanishChatCompleted() {
    return this.progress.spanishChatCompleted;
  }
  get analyticsCompleted() {
    return this.progress.analyticsCompleted;
  }
  get cmsCompleted() {
    return this.progress.cmsCompleted;
  }
  get responseTimeCompleted() {
    return this.progress.responseTimeCompleted;
  }
  get specialRequestCompleted() {
    return this.progress.specialRequestCompleted;
  }
}
