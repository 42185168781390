import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/core/user.service';
import { take } from 'rxjs/operators';

/**
 * This component renders the user's profile picture that is stored
 * on Firestore, if not it will render the UserAvatarComponent with
 * the user's name.
 *
 * TODO: Maybe this component should be merged with UserAvatarComponent.
 */
@Component({
  selector: 'app-avatar-for-email',
  templateUrl: './avatar-for-email.component.html',
  styleUrls: ['./avatar-for-email.component.scss'],
})
export class AvatarForEmailComponent implements OnInit {
  @Input() email: string;
  @Input() name: string;
  @Input() size: number = 24;

  isLoading = true;
  imgUrl: string = null;

  constructor(private usersService: UserService) {}

  /**
   * Gets the user's profile picture based on its email.
   */
  ngOnInit(): void {
    if (this.email) {
      this.usersService
        .getUserImageUrlFromEmail$(this.email)
        .pipe(take(1))
        .subscribe((imgUrl) => {
          console.log('imgurl is', imgUrl);
          this.imgUrl = imgUrl;
          this.isLoading = false;
        });
      this.isLoading = false;
    }
  }
}
