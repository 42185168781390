import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';

/**
 * Service used to store timestampts and current status of the current user
 * in the Firebase Realtime Database. This information can be used as audit
 * log but also for check who is online and its last connection.
 */
@Injectable({
  providedIn: 'root',
})
export class UserPresenceService {
  /**
   * If there is a user logged in it will trigger the activatePPresence.
   */
  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth
  ) {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.activatePresence();
      }
    });
  }

  /**
   * Logs values for connection and disconnection values on the Firebase
   * Realtime Database.
   */
  activatePresence() {
    // Fetch the current user's ID from Firebase Authentication.
    this.afAuth.currentUser.then((user) => {
      const uid = user.uid;
      console.log('user id is', uid);
      // Create a reference to this user's specific status node.
      // This is where we will store data about being online/offline.
      const userStatusDatabaseRef = this.db.database.ref('/status/' + uid);
      // We'll create two constants which we will write to
      // the Realtime database when this device is offline
      // or online.
      const isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      };
      const isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      };
      // Create a reference to the special '.info/connected' path in
      // Realtime Database. This path returns `true` when connected
      // and `false` when disconnected.
      this.db.database.ref('.info/connected').on('value', (snapshot) => {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
          return;
        }
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(() => {
            userStatusDatabaseRef.set(isOnlineForDatabase);
          });
      });
    });
  }
}
