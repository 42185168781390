import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewSiteTicket, NewSiteTicketProgress } from '../NewSiteTicketModel';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-site-ticket-billing-contact',
  templateUrl: './new-site-ticket-billing-contact.component.html',
  styleUrls: ['./new-site-ticket-billing-contact.component.scss'],
})
export class NewSiteTicketBillingContactComponent implements OnInit {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;
  @Output() changeCurrentStep = new EventEmitter<string>();

  skipStep = false;
  hasCreditCardSetup = false;
  billingContactName: string = null;
  emails: {
    email?: string;
    saved?: boolean;
    isPlaceHolder?: boolean;
    showButton?: boolean;
  }[] = [{ email: null, isPlaceHolder: true, showButton: false }];
  isLoading = true;

  constructor(private snackBar: MatSnackBar) {}

  /**
   * Flag indicating that no email or contact name has been provided.
   */
  get notTouched() {
    return !this.emails || !this.billingContactName;
  }

  /**
   * Initialize the variables used in this component from input data and creates
   * an empty placholders for emails.
   */
  ngOnInit() {
    this.billingContactName = this.model.billingContactName;
    this.emails = this.model.billingEmails.map((x) => {
      return { email: x, saved: true, isPlaceHolder: false };
    });
    this.emails.push({
      email: null,
      isPlaceHolder: true,
      showButton: false,
    });
    this.skipStep =
      this.progress.billingContactCompleted && !this.billingContactName;
    window.setTimeout(() => (this.isLoading = false), 500);
  }

  /**
   * Flag indicating wheter the given email is a valid email using a regular
   * expression.
   */
  isEmail(email: string) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * Flag indicating if there is duplicated emails or not.
   */
  isDuplicatedEmail(email) {
    return this.emails.filter((e) => e.email == email.email).length > 1;
  }

  /**
   * Checks if there is duplicates on the given array.
   */
  checkIfDuplicateExists(w: string[]): boolean {
    return new Set(w).size !== w.length;
  }

  /**
   * Deletes the given email from the emails array.
   */
  deleteEmail(email) {
    const index = this.emails.indexOf(email);
    this.emails.splice(index, 1);
    this.emails = [].concat(this.emails);
  }

  /**
   * Flag indicating whether the form is valid e.g. there is emails with placeholder,
   * valids and not duplicated; and the billing contact name was provided.
   */
  isValid() {
    if (!this.emails) return false;
    const emailsWithoutPlaceHolder = this.emails.filter(
      (x) => !x.isPlaceHolder
    );
    if (emailsWithoutPlaceHolder.length < 1) return false;
    let invalidEmail = emailsWithoutPlaceHolder.find(
      (email) => !this.isEmail(email.email)
    );

    let emailListValid = !invalidEmail;

    if (emailListValid) {
      // check for duplicated emails
      const emailReduced = emailsWithoutPlaceHolder.map((x) => x.email);
      emailListValid = !this.checkIfDuplicateExists(emailReduced);
    }

    return (
      emailListValid &&
      this.billingContactName &&
      this.billingContactName.length > 0
    );
  }

  /**
   * Handler of the keyup event of the email input. Checks that the email is not
   * empty and creates an empty email placeholder if needed.
   */
  onKeyUp(email: {
    email?: string;
    saved?: boolean;
    isPlaceHolder?: boolean;
    showButton?: boolean;
  }) {
    if (email.email && email.email.length > 0) {
      email.isPlaceHolder = false;
      this.searchForPlaceholder();
    }
  }

  /**
   * Checks that there is emails with placeholder or creates an empty placeholder.
   */
  searchForPlaceholder() {
    const placeHolder = this.emails.find((x) => x.isPlaceHolder);
    if (!placeHolder) {
      this.emails.push({
        email: null,
        saved: false,
        isPlaceHolder: true,
        showButton: true,
      });
    }
  }

  /**
   * When the form is submitted it will save its values or ignore then and
   * continue to the next step.
   */
  onSubmit() {
    if (this.skipStep) {
      this.skip();
    } else {
      this.saveChanges();
    }
  }

  /**
   * Skips to the next step after deleting any modification made to the shared
   * object in this component.
   */
  skip() {
    if (!this.skipStep) return;

    this.model.billingContactName = null;
    this.model.billingEmails = [];

    this.progress.billingContactCompleted = true;
    this.changeCurrentStep.emit('spanishChat');
  }

  /**
   * Checks that is valid, formats its data and then saves it. Finally
   * it will navigate to the next step.
   */
  saveChanges() {
    if (this.skipStep) return;

    const emailsNotEmpty = this.emails.filter(
      (x) => x.email && !x.isPlaceHolder
    );
    if (emailsNotEmpty.length < 1) {
      this.snackBar.open('You have to provide at least 1 email', 'Ok');
      return;
    }

    this.model.billingContactName = this.billingContactName;
    this.model.billingEmails = this.emails
      .filter((x) => x.email)
      .map((x) => x.email);

    this.progress.billingContactCompleted = true;
    this.changeCurrentStep.emit('spanishChat');
  }
}
