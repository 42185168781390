import { FeatherModule } from 'angular-feather';
import { NgModule } from '@angular/core';
import {
  PieChart,
  MessageCircle,
  BookOpen,
  User,
  Settings,
  Box,
  CreditCard,
  Clipboard,
  Users,
  Columns,
  Filter,
  ChevronDown,
  Globe,
  Smartphone,
  Edit,
  Clock,
  FileText,
  ChevronRight,
  AlertTriangle,
  UserPlus,
  UserMinus,
  Info,
  Folder,
  Mail,
  Phone,
  Book,
  Edit2,
  Triangle,
  PhoneCall,
  Search,
  CheckCircle,
  X,
  PlusCircle,
  Trash2,
  Cpu,
  ArrowLeft,
  HelpCircle,
  Trash,
  ArrowDown,
  Home,
  Lock,
  Grid,
  AlertCircle,
  Award,
} from 'angular-feather/icons';

const icons = {
  PieChart,
  MessageCircle,
  BookOpen,
  User,
  Settings,
  Box,
  CreditCard,
  Clipboard,
  Users,
  Columns,
  Filter,
  ChevronDown,
  Globe,
  Smartphone,
  Edit,
  Clock,
  FileText,
  ChevronRight,
  AlertTriangle,
  UserPlus,
  UserMinus,
  Info,
  Folder,
  Mail,
  Phone,
  Book,
  Edit2,
  Triangle,
  PhoneCall,
  HelpCircle,
  Search,
  CheckCircle,
  X,
  PlusCircle,
  Trash2,
  Cpu,
  ArrowLeft,
  Trash,
  ArrowDown,
  Home,
  Lock,
  Grid,
  AlertCircle,
  Award,
};

@NgModule({
  imports: [FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class FiconsModule {}
