import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { NewSiteTicket, NewSiteTicketProgress } from '../NewSiteTicketModel';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-site-ticket-website',
  templateUrl: './new-site-ticket-website.component.html',
  styleUrls: ['./new-site-ticket-website.component.scss'],
})
export class NewSiteTicketWebsiteComponent implements OnInit, OnDestroy {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;
  @Output() changeCurrentStep = new EventEmitter<string>();

  urlRegex =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  urls: {
    url?: string;
    saved?: boolean;
    isPlaceHolder?: boolean;
    showButton?: boolean;
  }[] = [{ url: null, isPlaceHolder: true, showButton: false }];

  isLoading = true;
  isCompleted = false;
  unsaved = false;

  constructor(private snackBar: MatSnackBar) {}

  /**
   * Search if there is placeholder on the URLs and creates one if not.
   */
  searchForPlaceholder() {
    const placeHolder = this.urls.find((x) => x.isPlaceHolder);
    if (!placeHolder) {
      this.urls.push({
        url: null,
        saved: false,
        isPlaceHolder: true,
        showButton: true,
      });
    }
  }

  /**
   * Initializes the urls array that is going to be used as form group
   * of the component.
   */
  ngOnInit() {
    this.urls = this.model.websiteUrls.map((x) => {
      return { url: x, saved: true, isPlaceHolder: false };
    });
    this.urls.push({
      url: null,
      isPlaceHolder: true,
      showButton: false,
    });
    window.setTimeout(() => (this.isLoading = false), 500);
  }

  /**
   * Uses a regular expression to test if the given string is an URL.
   */
  isUrl(url: string) {
    var re = this.urlRegex;
    return re.test(String(url).toLowerCase());
  }

  /**
   * Checks if the component's "form" is valid.
   */
  isValid() {
    if (!this.urls) return false;
    const urlsWithoutPlaceHolder = this.urls.filter((x) => !x.isPlaceHolder);
    if (urlsWithoutPlaceHolder.length < 1) return false;
    const invalidUrl = urlsWithoutPlaceHolder.find(
      (url) => !this.isUrl(url.url)
    );
    const urlListValid = !invalidUrl;

    return urlListValid;
  }

  /**
   * Not implemented.
   */
  ngOnDestroy() {}

  /**
   * Deletes the given URL from the array.
   */
  deleteUrl(url) {
    const index = this.urls.indexOf(url);
    this.urls.splice(index, 1);
    this.urls = [].concat(this.urls);
    this.unsaved = true;
  }

  /**
   * Callback used for the input's keyup event, if the url is valid, searchs for
   * a placeholder.
   */
  onKeyUp(url: {
    url?: string;
    saved?: boolean;
    isPlaceHolder?: boolean;
    showButton?: boolean;
  }) {
    if (url.url && url.url.length > 0) {
      url.isPlaceHolder = false;
      this.searchForPlaceholder();
    }
  }

  /**
   * When the form is submitted it will emit an event after verifying that
   * there is no errors.
   */
  saveChanges() {
    const urlsNotEmpty = this.urls.filter((x) => x.url && !x.isPlaceHolder);
    if (urlsNotEmpty.length < 1) {
      this.snackBar.open('You have to provide at least 1 url', 'Ok');
      return;
    }

    this.model.websiteUrls = this.urls.filter((x) => x.url).map((x) => x.url);
    this.progress.websiteCompleted = true;
    this.changeCurrentStep.emit('notifications');
  }
}
