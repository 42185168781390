import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CclGroupFS } from '../../dto/CclGroupFS';
import { MainFirestoreService } from '../main-firestore.service';

@Injectable({
  providedIn: 'root',
})
export class CclGroupsService extends MainFirestoreService<CclGroupFS> {
  groupsCollectionId: string = 'ccl-groups';

  constructor(readonly _firestore: AngularFirestore) {
    super(_firestore);
  }

  getGroupServiceById(groupId: string | number) {
    return this.getOne(this.groupsCollectionId, `${groupId}`);
  }

  updateCclGroup(groupId: string | number, cclGroupNewInfo: CclGroupFS) {
    return this.update(this.groupsCollectionId, `${groupId}`, cclGroupNewInfo);
  }
}
