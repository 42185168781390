export enum TIME_ZONES {
  'US/Central' = 'US/Central',
  'US/Eastern' = 'US/Eastern',
  'US/Mountain' = 'US/Mountain',
  'US/Pacific' = 'US/Pacific',
  'US/Hawaii' = 'US/Hawaii',
  'US/Alaska' = 'US/Alaska',
  'US/Arizona' = 'US/Arizona',
  UTC = 'UTC',
}
