import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { SiteEditorComponent } from '../site-editor/site-editor.component';
import { LiveChatService } from '../../live-chat-leads/shared/live-chat.service';
import { LiveChatSite } from '../../live-chat-leads/shared/live-chat-site';
import { UserService } from 'src/app/core/user.service';
import { isCclGlobalAdmin, hasAnyRole, GROUP_ADMIN } from 'src/app/core/user';
import { Notify } from 'src/app/shared/services/notify.service';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  isGlobalAdmin = false;
  isGroupAdmin = false;
  groups$: Observable<{ [groupName: string]: LiveChatSite[] }>;
  isSearchboxFocused = false;
  searchTerm = '';
  searchTerm$ = new BehaviorSubject(this.searchTerm);

  constructor(
    private userService: UserService,
    private lcService: LiveChatService,
    public notify: Notify,
    public dialog: MatDialog,
    private groupService: CclSelectedGroupService
  ) {}

  /**
   * Creates a subcription to get all the sites and filter them with the searchbar
   * by their name or invoice group's name
   */
  ngOnInit() {
    this.userService.currentUserWithId$.subscribe((user) => {
      this.isGlobalAdmin = isCclGlobalAdmin(user);
      this.isGroupAdmin = hasAnyRole(user, [GROUP_ADMIN]);
    });
    this.groupService.selectedId.subscribe((groupId) => {
      this.groups$ = this.lcService
        .getSitesByGroupWithNotifications(groupId)
        .pipe(
          flatMap((sites) => {
            return this.searchTerm$.pipe(
              map((_term) => {
                const term = _term.toLowerCase();
                return sites.filter(
                  (s) =>
                    s.name.toLowerCase().includes(term) ||
                    (s.invoice_group_name || '').toLowerCase().includes(term)
                );
              })
            );
          }),
          map((sites) => {
            // gets the name of all groups
            const groupNames = new Set(
              sites.map((site) => site.invoice_group_name)
            );
            // initializes empty site group object
            const groups = {};
            groupNames.forEach((name) => (groups[name] = []));
            // adds each site to its group's array
            sites.forEach((site) => groups[site.invoice_group_name].push(site));
            return groups;
          })
        );
    });
  }

  /**
   * Opens the SiteEditor component to create a support an
   * ``existing-site-ticket`` ticket.
   */
  openForm(group: any): void {
    let dialogRef;
    const context: any = { sites: group.value };
    dialogRef = this.dialog.open(SiteEditorComponent, { data: context });

    dialogRef.afterClosed().subscribe((submitted) => {
      if (submitted) {
        this.notify.success('Request submitted successfully');
      }
    });
  }

  /**
   * Updates the searchTerm Behaviour Subject value.
   */
  onSearchTermChanged() {
    this.searchTerm$.next(this.searchTerm);
  }
}
