import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { UserService } from 'src/app/core/user.service';
import { User } from 'src/app/core/user';
import { LiveChatService } from 'src/app/live-chat-leads/shared/live-chat.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import {
  NewSiteTicketProgress,
  NewSiteTicket,
  getNewSiteTicket,
} from '../NewSiteTicketModel';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { Notify } from 'src/app/shared/services/notify.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-site-creator',
  templateUrl: './site-creator.component.html',
  styleUrls: ['./site-creator.component.scss'],
})
export class SiteCreatorComponent implements OnInit, OnDestroy {
  defaultGroup: any;
  user: User;

  progress: NewSiteTicketProgress = {
    websiteCompleted: false,
    notificationsCompleted: false,
    billingContactCompleted: false,
    spanishChatCompleted: false,
    analyticsCompleted: false,
    cmsCompleted: false,
    responseTimeCompleted: false,
    specialRequestCompleted: false,
  };
  currentStep = 'websiteUrl';
  model: NewSiteTicket = getNewSiteTicket();

  /**
   * Adds a confirmation prompt when you want to close the modal.
   */
  constructor(
    private clService: LiveChatService,
    private groupService: CclSelectedGroupService,
    private userService: UserService,
    public dialogRef: MatDialogRef<SiteCreatorComponent>,
    public notify: Notify
  ) {
    dialogRef.disableClose = true;
    dialogRef.backdropClick().subscribe(() => {
      let cn = confirm('Would you like to close this session?');
      if (cn) {
        // Close the dialog
        dialogRef.close();
      }
    });
  }

  /**
   * Gets the current user and group.
   */
  ngOnInit() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
    });

    this.groupService.selectedId.subscribe((groupId) => {
      this.clService.allGroups$.subscribe((groups) => {
        this.defaultGroup = groups.find((g) => g.id == groupId);
      });
    });
  }

  ngOnDestroy() {}

  /**
   * When the form is submitted, closes the MatDialog.
   */
  onFormSubmitted() {
    this.dialogRef.close(true);
  }
}
