import { Injectable, Directive, forwardRef } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { CustomerService } from '../core/customer.service';

/**
 * Validator that checks if the Form Control has a valid coupon for
 * the current account.
 */
@Injectable({ providedIn: 'root' })
export class CouponValidator implements AsyncValidator {
  constructor(private customerService: CustomerService) {}

  validate(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return timer(750).pipe(
      switchMap(() => {
        if (!ctrl.value) {
          return of(null);
        }
        return this.customerService.validateCoupon(ctrl.value).pipe(
          map((isValid) => (isValid ? null : { coupon: true })),
          catchError(() => null)
        );
      })
    );
  }
}

/**
 * Custom directive that checks if the given form control has a valid
 * coupon.
 *
 * TODO: This directive seems unused. Check if its worth having it.
 */
@Directive({
  selector: '[appCoupon]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => CouponValidator),
      multi: true,
    },
  ],
})
export class CouponDirective {
  constructor(private validator: CouponValidator) {}

  validate(control: AbstractControl) {
    this.validator.validate(control);
  }
}
