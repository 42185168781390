import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { UUID } from 'angular2-uuid';
import { finalize } from 'rxjs/operators';
import { UploadedFile } from 'src/app/conversations/models/UploadedFile';
import { Notify } from 'src/app/shared/services/notify.service';
import { FILE_UPLOAD_STATES } from '../../enum/FileUpload';

@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  directory = 'chat-attachments/';

  constructor(
    private readonly _notify: Notify,
    private readonly _angularFireStorage: AngularFireStorage
  ) {}

  tryToUploadFile(state: string, file: UploadedFile) {
    switch (state) {
      case FILE_UPLOAD_STATES.EXCEEDS_MAX_SIZE: {
        this._notify.error('The maximum file size is 4mb');
        break;
      }
      case FILE_UPLOAD_STATES.WRONG_FILE_TYPE: {
        this._notify.error('Unsupported media type');
        break;
      }
      case FILE_UPLOAD_STATES.ACCEPTED: {
        this.uploadFile(file);
        break;
      }
    }
  }

  uploadFile(file: UploadedFile) {
    const filePath = this.directory + UUID.UUID() + '.' + file.fileExtension;
    this._angularFireStorage
      .upload(filePath, file.file)
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this._angularFireStorage
            .ref(filePath)
            .getDownloadURL()
            .subscribe((url) => {
              file.src = url;
              file.pending = false;
            });
        })
      )
      .subscribe();
  }
}
