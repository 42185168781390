export enum Role {
  User = 'user',
  Admin = 'admin',
}

export enum Status {
  Active = 'active',
  Inactive = 'inactive',
}

export interface AccountUser {
  role: Role;
  status: Status;
  isSetUp: boolean;
}

export interface IdAccountUser extends AccountUser {
  id: string;
}
