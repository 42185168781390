import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { map, mergeMap, mapTo, shareReplay } from 'rxjs/operators';

import { AccountService } from './account.service';
import { Template, IdTemplate } from './template';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  collectionName = 'templates';
  private _templates$: Observable<IdTemplate[]>;

  constructor(
    private accountService: AccountService,
    private afs: AngularFirestore
  ) {}

  get col$(): Observable<AngularFirestoreCollection<IdTemplate>> {
    return this.accountService.doc$.pipe(
      map((accountDoc) =>
        accountDoc.collection<IdTemplate>(this.collectionName)
      )
    );
  }

  getColByAccountId(
    accountId: string
  ): Observable<AngularFirestoreCollection<IdTemplate>> {
    return this.accountService
      .getDocById$(accountId)
      .pipe(
        map((accountDoc) =>
          accountDoc.collection<IdTemplate>(this.collectionName)
        )
      );
  }

  get templates$(): Observable<IdTemplate[]> {
    if (!this._templates$) {
      this._templates$ = this.accountService.doc$.pipe(
        mergeMap((accountDoc) => {
          const templateCollection = accountDoc.collection<Template>(
            this.collectionName,
            (ref) => ref.orderBy('name')
          );
          return templateCollection.snapshotChanges().pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as Template;
                const id = a.payload.doc.id;
                return { id, ...data } as IdTemplate;
              })
            )
          );
        }),
        shareReplay(1)
      );
    }
    return this._templates$;
  }

  getTemplatesByAccountId(accountId: string): Observable<IdTemplate[]> {
    return this.accountService.getDocById$(accountId).pipe(
      mergeMap((accountDoc) => {
        const templateCollection = accountDoc.collection<Template>(
          this.collectionName,
          (ref) => ref.orderBy('name')
        );
        return templateCollection.snapshotChanges().pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Template;
              const id = a.payload.doc.id;
              return { id, ...data } as IdTemplate;
            })
          )
        );
      }),
      shareReplay(1)
    );
  }

  getDoc$(templateId: string): Observable<AngularFirestoreDocument<Template>> {
    return this.col$.pipe(
      map((templateCol) => templateCol.doc<Template>(templateId))
    );
  }

  getDocByAccountId(
    accountdId: string,
    templateId: string
  ): Observable<AngularFirestoreDocument<Template>> {
    return this.getColByAccountId(accountdId).pipe(
      map((templateCol) => templateCol.doc<Template>(templateId))
    );
  }

  // Creates a new account & adds it to the current user's roles
  create(template: Template) {
    const id = this.afs.createId();
    return this.update({ id: id, ...template } as IdTemplate);
  }

  createByAccountId(accountId: string, template: Template) {
    const id = this.afs.createId();
    return this.updateByAccountId(accountId, {
      id: id,
      ...template,
    } as IdTemplate);
  }

  update(templateId: IdTemplate) {
    const template = templateId as Template;
    return this.getDoc$(templateId.id).pipe(
      map((templateDoc) => from(templateDoc.set(template)))
    );
  }

  updateByAccountId(accountId: string, templateId: IdTemplate) {
    const template = templateId as Template;
    return this.getDocByAccountId(accountId, templateId.id).pipe(
      map((templateDoc) => from(templateDoc.set(template)))
    );
  }

  // Partially updates a template
  partialUpdate(id: string, template: Partial<Template>) {
    return this.getDoc$(id).pipe(
      map((templateDoc) => from(templateDoc.update(template))),
      mapTo(true)
    );
  }

  // Deletes the given template
  delete(id: string) {
    return this.getDoc$(id).pipe(
      map((templateDoc) => from(templateDoc.delete())),
      mapTo(true)
    );
  }

  deleteByAccountId(accountId: string, templateId: string) {
    return this.getDocByAccountId(accountId, templateId).pipe(
      map((templateDoc) => from(templateDoc.delete())),
      mapTo(true)
    );
  }
}
