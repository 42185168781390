import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationStep } from '../models/NavigationStep';

/**
 * This component is used to render a navigation bar for any stepper,
 * it allows to lock, complete or skip steps with styling for each
 * of these states.
 *
 * TODO: State of each state handling can be refactored to improve
 * scalability.
 */
@Component({
  selector: 'app-stepper-navigation-bar',
  templateUrl: './stepper-navigation-bar.component.html',
  styleUrls: ['./stepper-navigation-bar.component.scss'],
})
export class StepperNavigationBarComponent implements OnInit {
  @Input() currentStep: string;
  @Input() steps: NavigationStep[];

  @Output() currentStepChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * Function triggered when an item of the navbar is clicked. It will
   * navigate to that item if it is not locked.
   */
  onClickNavbarItem(step) {
    const { value, isLocked } = step;
    if (!isLocked) {
      this.currentStep = value;
      this.currentStepChange.emit(value);
    }
  }
}
