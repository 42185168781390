import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

/**
 * A masked phone number input should have 10 digits entered. This handles
 * that validation through form control.
 *
 * TODO: Is this validation allowing phone numbers with letters? Consider
 * this and if the validation is too simple.
 */
export const phoneNumberValidator = (
  control: AbstractControl
): { [key: string]: any } | null => {
  if (!control.value) {
    return null;
  }
  const search = control.value.match(/\d/g);
  return search === null || search.length === 10
    ? null
    : { phoneNumber: { value: control.value } };
};

/**
 * Custom directive to validate if a phone number is valid.
 */
@Directive({
  selector: '[appPhoneNumber]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PhoneNumberDirective, multi: true },
  ],
})
export class PhoneNumberDirective implements Validator {
  /**
   * Uses the ``phoneNumberValidator`` to check if the given control has a valid
   * phone number.
   */
  validate(control: AbstractControl): { [key: string]: any } | null {
    return phoneNumberValidator(control);
  }
}
