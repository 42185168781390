import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber, parseNumber, ParsedNumber } from 'libphonenumber-js';

/**
 * Custom pipe to format a phone number into national US format, i.e.
 * (XXX) XXX-XXXX.
 */
@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  /**
   * Given a phone number, checks if it is a valid US phone number and then
   * perses it to National Format ((XXX) XXX-XXXX).
   */
  transform(value: any): string {
    if (!value) {
      return value;
    }
    const parsedNumber = parseNumber(value, 'US') as ParsedNumber;
    // if it's an empty object, it's an invalid number
    // just return the given value
    if (!parsedNumber || Object.keys(parsedNumber).length === 0) {
      return value;
    }
    return formatNumber(parsedNumber, 'National');
  }
}
