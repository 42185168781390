import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { UUID } from 'angular2-uuid';

const BASE_URL = environment.leadsEndpointUrl + '?apiKey=';

/**
 * Allows to activate/deactivate an url endpoint for CCL Groups to receive
 * leads.
 */
@Injectable({
  providedIn: 'root',
})
export class LeadsEndpointService {
  constructor(private afs: AngularFirestore) {}

  /**
   * Generates and returns an UUID
   */
  getNewApiKey(): string {
    return UUID.UUID();
  }

  /**
   * Updates the group's Firestore document to add a new API key and a leads
   * endpoint.
   */
  createEndpoint(groupId: number): Promise<any> {
    if (!groupId) throw Error('groupId is required');

    const apiKey = this.getNewApiKey();

    const doc = this.afs.doc(`ccl-groups/${groupId}`);
    const url = BASE_URL + apiKey;
    return doc.ref.set(
      {
        apiKey: apiKey,
        leadsEndpointUrl: url,
      },
      {
        merge: true,
      }
    );
  }

  /**
   * Deletes the group's stored API key and leads endpoint.
   */
  deleteEndpoint(groupId: number): Promise<any> {
    if (!groupId) throw Error('groupId is required');

    const doc = this.afs.doc(`ccl-groups/${groupId}`);
    return doc.ref.set(
      {
        apiKey: null,
        leadsEndpointUrl: null,
      },
      {
        merge: true,
      }
    );
  }
}
