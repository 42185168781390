export enum OrderField {
  Datetime = 'datetime',
  Rating = 'rating',
  Name = 'name',
  Location = 'location',
  Site = 'site',
}

export type OrderFieldMap = {
  [field in OrderField]: string;
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export interface Ordering {
  field: OrderField;
  direction: OrderDirection;
}
