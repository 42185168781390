import { SUPPORT_TICKET_TYPE } from './enum/SupportTicketType';

export interface NotificationTargetEmail {
  email: string;
}

export interface TimeRange {
  startTime: string;
  endTime: string;
}

export enum TimeZone {
  Pacific = 'Pacific',
  Mountain = 'Mountain',
  Central = 'Central',
  Eastern = 'Eastern',
  Hawaii = 'Hawaii',
  Alaska = 'Alaska',
}

export interface SupportTicket {
  datetimeSent?: any;
  id?: string;
  status: string;
  type: SUPPORT_TICKET_TYPE;
  updateUserId?: string;
  updateUserName?: string;
  user?: string; // The ID of the user who created the ticket
}

export interface IdSupportTicket extends SupportTicket {
  id: string;
}

export interface NewSiteSupportTicket extends SupportTicket {
  name: string;
  url: string;
  notificationTargetEmails: NotificationTargetEmail[];
  receiveTextNotifications: boolean;
  notificationTargetNumber: string;
  useLiveTransfer: boolean;
  liveTransferNumber: string;
  mondayRange: TimeRange;
  tuesdayRange: TimeRange;
  wednesdayRange: TimeRange;
  thursdayRange: TimeRange;
  fridayRange: TimeRange;
  saturdayRange: TimeRange;
  sundayRange: TimeRange;
  timeZone: TimeZone;
  needsIntegration: boolean;
  integrationName: string;
  useSpanishChat: boolean;
  specialRequests: string;
}

export interface ExistingSiteSupportTicket extends SupportTicket {
  siteNames: string[];
  newNotificationTargetNumber: string;
  newNotificationTargetEmail: string;
  oldNotificationTargetEmails: string[];
  otherRequest: string;
}

export enum InformationType {
  Reviews = 'Review Generator',
  LiveChat = 'Live Chat',
}

export interface InformationRequestSupportTicket extends SupportTicket {
  informationType: InformationType;
  userName?: string;
}
