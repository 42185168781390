import { Component, Input, OnInit } from '@angular/core';
import { LEAD_TYPE } from 'src/app/core/enum/LeadType';

/**
 * This component renders a proper icon based on the lead type provided.
 * Currently it uses a custom icon for chat leads and feather for the
 * others.
 */
@Component({
  selector: 'app-lead-type-icon',
  templateUrl: './lead-type-icon.component.html',
  styleUrls: ['./lead-type-icon.component.scss'],
})
export class LeadTypeIconComponent implements OnInit {
  @Input() leadType: string;

  leadTypes = LEAD_TYPE;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Chooses a feather icon (see https://feathericons.com/) based on the
   * lead's type.
   */
  get featherIcon() {
    switch (this.leadType) {
      case LEAD_TYPE.CALL_LEAD:
        return 'phone-call';
      case LEAD_TYPE.SMARTFORM_LEAD:
        return 'cpu';
    }
  }
}
