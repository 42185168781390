import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * This is a component that renders a MatDialog with the given data.
 */
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  title: string;
  content: string;
  buttons: Array<{ text: string; value: any; color?: string }>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  /**
   * Initializes the component properties with the MatDialog data.
   */
  ngOnInit() {
    if (this.data) {
      this.title = this.data.title;
      this.content = this.data.content;
      this.buttons = this.data.buttons || [
        { text: 'OK', value: true, color: 'primary' },
      ];
    }
  }
}
