import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap, map, mapTo, take, flatMap } from 'rxjs/operators';

import { AccountService } from './account.service';

export type MyBusinessLocation = {
  name: string;
  title: string;
  metadata: { placeId: string };
  storefrontAddress: {
    addressLines: string[];
    locality: string;
    administrativeArea: string;
    postalCode: string;
  };
};

declare var gapi;

@Injectable()
export class MyBusinessService {
  businessInfoAPI = 'https://mybusinessbusinessinformation.googleapis.com/v1/';
  businessManagmentAPI =
    'https://mybusinessaccountmanagement.googleapis.com/v1/';

  accountsEndpoint = `${this.businessManagmentAPI}accounts`;

  locationsFields = 'name,title,metadata,storefrontAddress';

  constructor(
    private accountService: AccountService,
    private http: HttpClient
  ) {}

  get accounts(): Observable<any[]> {
    return this.accountService.getAccessToken('Google').pipe(
      mergeMap((_) => {
        let user = gapi.auth2.getAuthInstance().currentUser.get();
        let oauthToken = user.getAuthResponse().access_token;

        return this.http.get<{ accounts: any }>(this.accountsEndpoint, {
          headers: { Authorization: 'Bearer ' + oauthToken },
        });
      }),
      map((res) => {
        if (Array.isArray(res.accounts)) {
          return res.accounts;
        }
        return [res.accounts];
      })
    );
  }

  getAccountsByAccountId(accountId: string): Observable<MyBusinessLocation[]> {
    return this.accountService
      .getAccessTokenByAccountId('Google', accountId)
      .pipe(
        mergeMap((_) => {
          let user = gapi.auth2.getAuthInstance().currentUser.get();
          let oauthToken = user.getAuthResponse().access_token;

          return this.http.get<{ accounts: any }>(this.accountsEndpoint, {
            headers: { Authorization: 'Bearer ' + oauthToken },
          });
        }),
        map((res) => {
          if (Array.isArray(res.accounts)) {
            return res.accounts;
          }
          return [res.accounts];
        })
      );
  }

  getLocations(urlSuffix: string): Observable<any[]> {
    return this.accountService.getAccessToken('Google').pipe(
      mergeMap((_) => {
        let user = gapi.auth2.getAuthInstance().currentUser.get();
        let oauthToken = user.getAuthResponse().access_token;

        return this.http.get<{ locations: any }>(
          this.businessInfoAPI + urlSuffix + '/locations',
          {
            params: { read_mask: this.locationsFields },
            headers: { Authorization: 'Bearer ' + oauthToken },
          }
        );
      }),
      map((res) => {
        if (Array.isArray(res.locations)) {
          return res.locations;
        }
        return [res.locations];
      })
    );
  }

  getLocationsByAccountId(
    urlSuffix: string,
    accountId: string
  ): Observable<MyBusinessLocation[]> {
    return this.accountService
      .getAccessTokenByAccountId('Google', accountId)
      .pipe(
        mergeMap((_) => {
          let user = gapi.auth2.getAuthInstance().currentUser.get();
          let oauthToken = user.getAuthResponse().access_token;

          return this.http.get<{ locations: any }>(
            this.businessInfoAPI + urlSuffix + '/locations',
            {
              params: { read_mask: this.locationsFields },
              headers: { Authorization: 'Bearer ' + oauthToken },
            }
          );
        }),
        map((res) => {
          if (Array.isArray(res.locations)) {
            return res.locations;
          }
          return [res.locations];
        })
      );
  }

  getLoggedUser() {
    return gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
  }

  createAdmin(resourceName: string): Observable<void> {
    return this.accountService.getAccessToken('Google').pipe(
      take(1),
      mergeMap((_) => {
        let user = gapi.auth2.getAuthInstance().currentUser.get();
        let oauthToken = user.getAuthResponse().access_token;

        return this.http.post<{ any }>(
          'https://mybusiness.googleapis.com/v4/' + resourceName + '/admins',
          {
            adminName: 'google@clientchatlive.com',
            role: 'MANAGER',
          },
          { headers: { Authorization: 'Bearer ' + oauthToken } }
        );
      }),
      mapTo(null)
    );
  }

  createAdminByLocationId(
    locationId: string,
    accountId: string
  ): Observable<void> {
    return this.accountService
      .getAccessTokenByAccountId('Google', accountId)
      .pipe(
        take(1),
        mergeMap((_) => {
          let user = gapi.auth2.getAuthInstance().currentUser.get();
          let oauthToken = user.getAuthResponse().access_token;
          return this.checkClientChatLiveIsAdmin(locationId, oauthToken);
        }),
        mapTo(null)
      );
  }

  checkClientChatLiveIsAdmin(
    locationId: string,
    oauthToken: string
  ): Observable<void> {
    return this.http
      .get<any>(`${this.businessManagmentAPI}locations/${locationId}/admins`, {
        headers: { Authorization: 'Bearer ' + oauthToken },
      })
      .pipe(
        flatMap((result) => {
          const clientChatLiveIsAdmin =
            result.admins.filter((x) => x.admin === 'Client Chat Live').length >
            0;
          if (clientChatLiveIsAdmin) {
            return of({});
          } else {
            return this.sendInviteToAdminGoogleMB(locationId, oauthToken);
          }
        })
      );
  }

  sendInviteToAdminGoogleMB(
    locationId: string,
    oauthToken: string
  ): Observable<any> {
    return this.http.post<{ any }>(
      `${this.businessManagmentAPI}locations/${locationId}/admins`,
      {
        admin: 'google@clientchatlive.com',
        role: 'MANAGER',
      },
      { headers: { Authorization: 'Bearer ' + oauthToken } }
    );
  }
}
