import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from './core/auth.service';
import { SpinnerService } from './core/spinner.service';
import { UserPresenceService } from './shared/services/user-presence.service';
import { Observable, Subscription } from 'rxjs';
import { IdUser } from './core/user';
import {
  WhiteLabel,
  WhiteLabelService,
} from './core/services/white-label.service';
import { map } from 'rxjs/operators';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { UserService } from './core/user.service';
import { MatDialog } from '@angular/material/dialog';
import { CclGroupsNotificationsService } from './notifications/services/ccl-groups-notifications.service';
import { ReviewsAccountsService } from './core/reviews-accounts.service';
import { Router } from '@angular/router';
import { isCclGlobalAdmin } from 'src/app/core/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  userDefaultUrl =
    'https://firebasestorage.googleapis.com/v0/b/client-chat-live.appspot.com/o/user.png?alt=media&token=81fa476c-dce4-4003-8817-e00e16342d55';

  userName$: Observable<string>;
  userName: string;
  user: IdUser;
  shouldDisplayReviews: boolean;
  shouldDisplayKnowledgeBase: boolean;
  profilePic = null;
  userSub: Subscription;
  whiteLabel: WhiteLabel;
  selectedAccountId: string;
  showDevFeatures: boolean = false;

  /**
   * Registers the bussiness platforms's (like Facebook, Google or Yelp) icon
   * as MatIcon.
   */
  constructor(
    public authService: AuthService,
    public spinnerService: SpinnerService,
    private presenceService: UserPresenceService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private userService: UserService,
    private titleService: Title,
    private dialog: MatDialog,
    private cclGroupsNotifications: CclGroupsNotificationsService,
    private readonly _whiteLabelService: WhiteLabelService,
    private reviewsAccounts: ReviewsAccountsService,
    public readonly _router: Router
  ) {
    iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl('assets/facebook-box.svg')
    );
    iconRegistry.addSvgIcon(
      'google',
      sanitizer.bypassSecurityTrustResourceUrl('assets/google.svg')
    );
    iconRegistry.addSvgIcon(
      'yelp',
      sanitizer.bypassSecurityTrustResourceUrl('assets/yelp.svg')
    );
  }

  /**
   * Adds a LogIn log on Firestore.
   */
  ngOnInit() {
    this.authService.createEvent();
    this.reviewsAccounts.selectedId$.subscribe(
      (id) => (this.selectedAccountId = id)
    );
    console.log('activating presence service', this.presenceService);
    this.getWhitLabelInformation();
    console.log(this.cclGroupsNotifications); // this is neccesary to activate this service
    this.titleService.setTitle(`${this.whiteLabel.provider} Dashboard`);
    this.userName$ = this.authService.user$.pipe(
      map((user) => user.displayName)
    );
    this.userSub = this.userService.currentUserWithId$.subscribe((user) => {
      this.user = user;
      this.showDevFeatures = isCclGlobalAdmin(user);
      this.userName = user.firstName + ' ' + user.lastName;
      if (user.profilePictureUrl) {
        this.profilePic = user.profilePictureUrl;
      } else {
        this.profilePic = this.userDefaultUrl;
      }
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  getWhitLabelInformation() {
    this.whiteLabel = this._whiteLabelService.whiteLabel;
    this.shouldDisplayReviews = !this._whiteLabelService.isWhiteLabel;
    this.shouldDisplayKnowledgeBase = !this._whiteLabelService.isWhiteLabel;
  }

  signOut() {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        title: 'Sign Out',
        content: 'Are you sure you want to sign out?',
        buttons: [
          {
            text: 'Cancel',
            value: false,
          },
          {
            text: 'Yes, sign out',
            value: true,
            color: 'primary',
          },
        ],
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.authService.signOut();
      }
    });
  }

  get isOnReviews(): boolean {
    return this._router.url.includes('/reviews/');
  }
}
