import { NavLink } from '../core/nav-link';
import * as memoizee from 'memoizee';
import { hasAnyRole, GROUP_ADMIN, GLOBAL_ADMIN } from '../core/user';
import { of, combineLatest } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { LiveChatService } from '../live-chat-leads/shared/live-chat.service';
import { CclSelectedGroupService } from '../live-chat-container/services/ccl-group-service.service';
import { AngularFirestore } from '@angular/fire/firestore';

/**
 * Object that specifies the routes showed in the side nabar (ay
 * the left of Live Chat session). Iw has the label, route and icon
 * pero each item.
 */
export const NavLinks: Array<NavLink> = [
  {
    path: ['/live-chat/support/settings'],
    label: 'Settings',
    icon: 'settings',
    iconFamily: 'feather',
  },
  {
    path: ['/live-chat/ccl-templates'],
    label: 'Templates',
    icon: 'box',
    iconFamily: 'feather',
  },
  {
    path: ['/live-chat/billing'],
    label: 'Billing',
    icon: 'credit-card',
    iconFamily: 'feather',
    isVisible: memoizee(
      (
        user,
        context: {
          cclGroupService: CclSelectedGroupService;
          lcService: LiveChatService;
        }
      ) => {
        const { cclGroupService, lcService } = context;
        console.log('context is', context);
        return of(hasAnyRole(user, [GROUP_ADMIN, GLOBAL_ADMIN])).pipe(
          flatMap((isAllowedByRole) => {
            if (!isAllowedByRole) return of(false);

            return cclGroupService.selectedId.pipe(
              flatMap((groupId) =>
                lcService.getGroupDetail(groupId.toString())
              ),
              map((group) => {
                console.log('group is', group);
                return (
                  !group.is_affiliate ||
                  (group.is_affiliate && group.link_to_affiliate)
                );
              })
            );
          })
        );
      }
    ),
  },
  {
    path: ['/live-chat/tickets'],
    label: 'Service Tickets',
    icon: 'clipboard',
    iconFamily: 'feather',
  },
  {
    path: ['/live-chat/user-profile'],
    label: 'Profile',
    icon: 'user',
    iconFamily: 'feather',
  },
  {
    path: ['/live-chat/setup'],
    label: 'Live chat setup',
    icon: 'settings_applications',
    isVisible: memoizee(
      (
        _,
        context: {
          cclGroupService: CclSelectedGroupService;
          afs: AngularFirestore;
        }
      ) => {
        const { cclGroupService, afs } = context;
        const groupObservable = cclGroupService.sureGetCclGroupWithId();

        return groupObservable.pipe(
          flatMap((group) => {
            const disabledObs = afs
              .doc(`ccl-disabled-groups-setup/${group.id}`)
              .get();

            return combineLatest(disabledObs).pipe(
              map(([disabledDoc]) => {
                const liveChatDisabled = disabledDoc.exists;
                return !liveChatDisabled;
              })
            );
          })
        );
      }
    ),
  },
];
