import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MessageFS } from 'src/app/firebase/dto/MessageFS';
import { ChatAttachment } from 'src/app/core/models/ChatAttachment';
import { ConversationFS } from 'src/app/firebase/dto/ConversationFS';
import { UploadedFile } from '../models/UploadedFile';
import { MessagesService } from 'src/app/firebase/firestore/ccl-groups/conversations/messages/messages.service';
import { ScheduledSmsFS } from 'src/app/firebase/dto/ScheduledSmsFS';
import { ScheduledSmsService } from 'src/app/firebase/firestore/scheduled-sms/scheduled-sms.service';
import { UserService } from 'src/app/core/user.service';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { IdUser } from 'src/app/core/user';
import { FileUploaderService } from 'src/app/core/services/file-uploader/file-uploader.service';

export interface ConversationPdfRequestMessage {
  type: 0;
  operator: boolean;
  text: string;
  participant: string;
}

export interface ConversationPdfRequest {
  visitor_name: string;
  visitor_email?: string;
  visitor_phone?: string;
  messages: ConversationPdfRequestMessage[];
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  files: UploadedFile[] = [];
  conversationId = new BehaviorSubject<string>(null);
  messageSent = new Subject<any>();
  user: IdUser;
  groupId: number;

  constructor(
    private readonly _messagesService: MessagesService,
    private readonly _scheduledSmsService: ScheduledSmsService,
    private readonly _userService: UserService,
    private readonly _groupService: CclSelectedGroupService,
    private readonly _fileUploaderService: FileUploaderService
  ) {
    this._userService.currentUserWithId$.subscribe(
      (user) => (this.user = user)
    );
    this._groupService.selectedId.subscribe((groupId) => {
      this.groupId = groupId;
      this.conversationId.next(null);
    });
  }

  tryToSendMessage(
    conversation: ConversationFS,
    message: string,
    scheduledMessageInfo?
  ) {
    const attachments: ChatAttachment[] =
      this.files.length > 0
        ? this.files
            .filter((x) => !x.pending)
            .map((file) => ({
              url: file.src,
            }))
        : [];
    const newMessage: MessageFS = {
      senderId: this.user.id,
      senderName: this.user.firstName + ' ' + this.user.lastName,
      text: message,
      to: conversation.phone,
    };
    this.files = [];
    if (attachments.length > 0) {
      newMessage.attachments = attachments;
    }
    return scheduledMessageInfo
      ? this.scheduleMessage(newMessage, scheduledMessageInfo)
      : this.sendMessage(newMessage);
  }

  sendMessage(newMessage: MessageFS) {
    return this._messagesService
      .saveMessage(this.groupId, this.conversationId.value, newMessage)
      .then((_) => newMessage);
  }

  scheduleMessage(message: MessageFS, scheduledMessageInfo) {
    const { scheduledDate, timezone } = scheduledMessageInfo;
    const messageToSchedule: ScheduledSmsFS = {
      cclGroup: this.groupId,
      conversationId: this.conversationId.value,
      scheduledMessage: message,
      scheduledDate,
      timezone,
    };
    return this._scheduledSmsService
      .scheduleMessage(messageToSchedule)
      .then((_) => message);
  }

  tryToUploadFile(state: string, files?: UploadedFile[]) {
    this.files = files || [];
    this._fileUploaderService.tryToUploadFile(
      state,
      this.files[this.files.length - 1]
    );
  }
}
