import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewSiteTicket, NewSiteTicketProgress } from '../NewSiteTicketModel';
import { SupportTicketService } from 'src/app/core/support-ticket.service';
import { UserService } from 'src/app/core/user.service';
import { User } from 'src/app/core/user';
import { LiveChatService } from 'src/app/live-chat-leads/shared/live-chat.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { SUPPORT_TICKET_TYPE } from 'src/app/core/enum/SupportTicketType';

@Component({
  selector: 'app-new-site-ticket-submit',
  templateUrl: './new-site-ticket-submit.component.html',
  styleUrls: ['./new-site-ticket-submit.component.scss'],
})
export class NewSiteTicketSubmitComponent implements OnInit {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;

  @Output() onFormSubmitted = new EventEmitter();
  @Output() changeCurrentStep = new EventEmitter<string>();
  isLoading = true;
  isLoadingSubmiting = false;

  user: User;
  defaultGroup: any;

  constructor(
    private snackBar: MatSnackBar,
    private stService: SupportTicketService,
    private userService: UserService,
    private groupService: CclSelectedGroupService,
    private lcService: LiveChatService
  ) {}

  /**
   * Gets the current group and user.
   */
  ngOnInit() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
    });

    this.groupService.selectedId.subscribe((groupId) => {
      this.lcService.allGroups$.subscribe((groups) => {
        this.defaultGroup = groups.find((g) => g.id == groupId);
      });
    });

    window.setTimeout(() => (this.isLoading = false), 500);
  }

  /**
   * Enables a Loader incator while the form is being submitted.
   */
  onSubmit() {
    this.isLoadingSubmiting = true;
    this.submit();
  }

  /**
   * When the form is submitted creates a ``new-site-ticket-v2`` ticket
   * and emits an event.
   */
  submit() {
    this.stService
      .create({
        subject: 'New Site Request',
        description: 'No description',
        userName: this.user.firstName + ' ' + this.user.lastName,
        groupId: this.defaultGroup.id,
        groupName: this.defaultGroup.name,
        groupClientHref: this.defaultGroup.client_uref,
        type: SUPPORT_TICKET_TYPE.NEW_SITE_V2,
        status: 'open',
        ...this.model,
      })
      .subscribe(
        () => this.onFormSubmitted.emit(),
        (err) => {
          console.log(err);
          this.snackBar.open("Request couldn't be sent. Please try again", '', {
            panelClass: 'bg-warn',
          });
        }
      );
  }
}
