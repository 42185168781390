import { Component, OnInit, OnDestroy } from '@angular/core';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { FacebookService } from '../services/facebook.service';
import { MatDialog } from '@angular/material/dialog';
import { FacebookSetupModalComponent } from '../facebook-setup-modal/facebook-setup-modal.component';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

/**
 * This component was built to show the group's sites with the Facebook integration
 * enabled. It also has information about the integration and a button to trigger a
 * new integration.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-facebook-sites',
  templateUrl: './facebook-sites.component.html',
  styleUrls: ['./facebook-sites.component.scss'],
})
export class FacebookSitesComponent implements OnInit, OnDestroy {
  isLoading = true;
  groupId: number;
  sites: any[];
  groupIdSub: Subscription;

  constructor(
    private dialog: MatDialog,
    private cclGroupService: CclSelectedGroupService,
    private facebookService: FacebookService
  ) {}

  /**
   * Gets the current group and its sites.
   */
  ngOnInit(): void {
    this.groupIdSub = this.cclGroupService.selectedId.subscribe((groupId) => {
      this.groupId = groupId;
      this.sites = [];
      if (this.groupId) {
        this.loadSites();
      }
    });
  }

  /**
   * Not implemented.
   */
  ngOnDestroy(): void {}

  /**
   * Gets all the sites of the group that have Facebook enable.
   */
  loadSites() {
    this.facebookService.getSitesEnabled(this.groupId).subscribe((sites) => {
      this.sites = sites;
      this.isLoading = false;
    });
  }

  /**
   * Opens the given link on a new window.
   */
  goToLink(link: string) {
    var win = window.open(link, '_blank');
    win.focus();
  }

  /**
   * Opens the Facebook Set up MatModal.
   */
  onCreateFBTicket() {
    this.dialog.open(FacebookSetupModalComponent);
  }
}
