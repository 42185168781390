import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * This component is a time picker with options generated from every
 * half hours. It will update the selected value to a given formGroup
 * with the ``time`` control.
 *
 * TODO: Refactor to allow two-way data binding if needed.
 */
@Component({
  selector: 'app-custom-time-picker',
  templateUrl: './custom-time-picker.component.html',
  styleUrls: ['./custom-time-picker.component.scss'],
})
export class CustomTimePickerComponent implements OnInit {
  @Input() timeFormGroup: FormGroup;
  @Input() halfHours = ['00', '30'];

  timeSpans: string[];

  /**
   * Initializes the picker options.
   */
  constructor() {
    this.timeSpans = this.getTimeSpans();
  }

  ngOnInit(): void {}

  /**
   * Generates the array of timespans that are going to be the options of
   * the picker.
   */
  getTimeSpans(): any[] {
    const times = [];
    for (let i = 0; i < 24; i++) {
      for (const quarter of this.halfHours) {
        const hour = i < 10 ? '0' + i : i;
        times.push(`${hour}:${quarter}`);
      }
    }
    return times;
  }

  /**
   * When an option is selected it will update the form control ``time``
   * value.
   */
  selectTimeSpan(timeSpan: string) {
    this.timeFormGroup.controls['time'].setValue(timeSpan);
  }
}
