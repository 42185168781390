import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, flatMap } from 'rxjs/operators';
import { UserService } from 'src/app/core/user.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AccountWithId, Account } from './account';

@Injectable({
  providedIn: 'root',
})
export class ReviewsAccountsService {
  private _selectedId$ = new BehaviorSubject<string>(null);

  constructor(
    private userService: UserService,
    private afs: AngularFirestore
  ) {}

  get selectedId$() {
    return this._selectedId$.asObservable();
  }

  setSelectedId(id: string) {
    this._selectedId$.next(id);
  }

  getAllAccounts(): Observable<AccountWithId[]> {
    return this.userService.currentUserWithId$.pipe(
      flatMap((user) => {
        if (user.isSuperAdmin) {
          return this.afs
            .collection<Account>(`accounts`)
            .snapshotChanges()
            .pipe(
              map((actions) =>
                actions.map((a) => {
                  const data = a.payload.doc.data();
                  const id = a.payload.doc.id;
                  return { id, ...data } as AccountWithId;
                })
              )
            );
        }
      })
    );
  }

  getAccount(accountId): Promise<AccountWithId> {
    return this.afs
      .doc(`accounts/${accountId}`)
      .ref.get()
      .then((account) => {
        console.log('selectedAccount', account, account.data());
        return {
          id: accountId,
          ...(account.data() as any),
        } as AccountWithId;
      });
  }
}
