import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

/**
 * This component is used to atomize the implementation of the MatCheckbox
 * indeterminate state, comonly used with the 'Select All' checkbox. It uses a
 * FormControl.
 *
 * TODO: This component seems unused on all the project, but it can be used on
 * certain parts of the code, study were it can be implemented.
 */
@Component({
  selector: 'app-select-all-checkbox',
  template: `
    <mat-checkbox
      class="mat-option"
      [disableRipple]="true"
      [indeterminate]="isIndeterminate()"
      [checked]="isChecked()"
      (click)="toggleCheckbox($event)"
    >
      {{ text }}
    </mat-checkbox>
  `,
  styles: [''],
})
export class SelectAllCheckboxComponent implements OnInit {
  @Input() control: FormControl;
  @Input() values = [];
  @Input() text = 'Select All';
  @Input() valuesMap: (any) => any;
  @Input() toggleOnInit = false;
  @ViewChild(MatCheckbox, { static: true }) checkbox: MatCheckbox;

  constructor() {}

  /**
   * It will trigger ther checkbox toggle if required.
   */
  ngOnInit() {
    if (this.toggleOnInit) {
      this.toggleCheckbox();
    }
  }

  /**
   * Flag reflecting the checked state of the checkbox, it will be true if all
   * the items (checkboxes) were selected.
   */
  isChecked(): boolean {
    return (
      this.control.value &&
      this.values.length &&
      this.control.value.length === this.values.length
    );
  }

  /**
   * Flag reflecting the indeterminate state of the checkbox, it will be true if
   * not all the values were selected or not all the component properties were
   * provided.
   */
  isIndeterminate(): boolean {
    return (
      this.control.value &&
      this.values.length &&
      this.control.value.length &&
      this.control.value.length < this.values.length
    );
  }

  /**
   * Custom handler of the checkbox click event.
   */
  toggleCheckbox(event?: any) {
    // stop event; checkbox will be toggled manually to be synchronous
    if (event) {
      event.preventDefault();
    }
    this.checkbox.toggle();

    // set control value dependending on new checkbox value
    if (this.checkbox.checked) {
      if (this.valuesMap) {
        // use valuesMap function to pluck value
        this.control.setValue(this.values.map(this.valuesMap));
      } else {
        this.control.setValue(this.values);
      }
    } else {
      this.control.setValue([]);
    }
  }
}
