import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { OrderDirection } from 'src/app/reviews/shared/ordering';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/conversations/services/chat.service';
import { CclSelectedGroupService } from 'src/app/live-chat-container/services/ccl-group-service.service';
import { Router } from '@angular/router';
import { GroupNotificationFS } from 'src/app/firebase/dto/GroupNotificationFS';

@Injectable({
  providedIn: 'root',
})
export class CclGroupsNotificationsService {
  groupNotificationsSub: Subscription;
  alreadyNotified: string[] = [];
  mutedConversationId: string = null;

  constructor(
    lcService: CclSelectedGroupService,
    private afs: AngularFirestore,
    private toastr: ToastrService,
    chatService: ChatService,
    private readonly _router: Router,
    private readonly _chatService: ChatService
  ) {
    lcService.selectedId.subscribe((groupId) => {
      if (groupId) {
        this.suscribeToNotificationsGroup(groupId);
      }
    });
    chatService.conversationId.subscribe((id) => {
      this.mutedConversationId = id;
    });
  }

  suscribeToNotificationsGroup(groupId) {
    if (this.groupNotificationsSub) {
      this.groupNotificationsSub.unsubscribe();
    }

    this.groupNotificationsSub = this.afs
      .collection(`ccl-groups/${groupId}/group-notifications`, (ref) =>
        ref.orderBy('dateCreated', OrderDirection.Desc).limit(10)
      )
      .snapshotChanges(['added'])
      .subscribe((changes) => {
        let newNotifications = changes
          .filter((x) => x.type == 'added')
          .map((x) => {
            return {
              id: x.payload.doc.id,
              ...(x.payload.doc.data() as any),
            } as any;
          });
        newNotifications = newNotifications.filter((not) => {
          const seconds =
            (new Date().getTime() - not.dateCreated.toDate().getTime()) / 1000;
          return seconds < 10 && !this.alreadyNotified.find((x) => x == not.id);
        });

        newNotifications.forEach((not) => {
          this.alreadyNotified.push(not.id);
          if (not.conversationId != this.mutedConversationId) {
            this.showNotifification(not);
          }
        });
      });
  }

  showNotifification(notification: GroupNotificationFS) {
    const { message, conversationId } = notification;
    this.toastr
      .info(message, 'New Message Received:', {
        closeButton: true,
        positionClass: 'toast-bottom-right',
        toastClass: 'ngx-toastr sms-icon',
      })
      .onTap.subscribe((_) => {
        this._chatService.conversationId.next(conversationId);
        this._router.navigate(['/live-chat/conversations']);
      });
  }
}
