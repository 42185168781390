import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ScheduledSmsFS } from '../../dto/ScheduledSmsFS';
import { MainFirestoreService } from '../main-firestore.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduledSmsService extends MainFirestoreService<ScheduledSmsFS> {
  protected scheduledSmsCollectionId = 'scheduled-sms';

  constructor(readonly _firestore: AngularFirestore) {
    super(_firestore);
  }

  scheduleMessage(scheduledMessage: ScheduledSmsFS) {
    return this.create(this.scheduledSmsCollectionId, scheduledMessage);
  }

  getScheduledMessagesFromConversation(
    groupId: number,
    conversationId: string
  ) {
    return this.get(this.scheduledSmsCollectionId, (ref) =>
      ref
        .where('cclGroup', '==', groupId)
        .where('conversationId', '==', conversationId)
        .orderBy('scheduledDate')
    );
  }

  cancelScheduledMessage(message: ScheduledSmsFS) {
    return this.delete(this.scheduledSmsCollectionId, message.id);
  }
}
