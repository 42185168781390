import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewSiteTicket, NewSiteTicketProgress } from '../NewSiteTicketModel';

@Component({
  selector: 'app-new-site-ticket-cms',
  templateUrl: './new-site-ticket-cms.component.html',
  styleUrls: ['./new-site-ticket-cms.component.scss'],
})
export class NewSiteTicketCmsComponent implements OnInit {
  @Input() model: NewSiteTicket;
  @Input() progress: NewSiteTicketProgress;
  @Output() changeCurrentStep = new EventEmitter<string>();
  isLoading = true;
  isCompleted = false;
  cms: string;

  constructor() {}

  /**
   * Initializes component's properties based on input data.
   */
  ngOnInit() {
    this.cms = this.model.cms;
    this.isCompleted = this.progress.cmsCompleted === true;
    window.setTimeout(() => (this.isLoading = false), 500);
  }

  ngOnDestroy() {}

  /**
   * When the form is submitted it will ignore its values if they are empty
   * and save them if not, then continues to the next step.
   */
  onSubmit() {
    if (!this.cms) {
      this.onSkip();
      return;
    }
    this.model.cms = this.cms;
    this.progress.cmsCompleted = true;
    this.changeCurrentStep.emit('responseTime');
  }

  /**
   * Since this step is optional this function continues to the next step
   * without saving any data.
   */
  onSkip() {
    this.progress.cmsCompleted = true;
    this.changeCurrentStep.emit('responseTime');
  }
}
