import { Component, OnInit } from '@angular/core';

/**
 * This component is used only as a container for the auto-responder setup
 * components.
 */
@Component({
  selector: 'app-auto-responder-container',
  templateUrl: './auto-responder-container.component.html',
  styleUrls: ['./auto-responder-container.component.css'],
})
export class AutoResponderContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
