import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { OrderDirection } from '../reviews/shared/ordering';
import firebase from 'firebase';
import { SUPPORT_TICKET_TYPE } from '../core/enum/SupportTicketType';

interface NewSupportTicket {
  subject: string;
  sites: any[];
  notificationTargets: any[];
  description: string;
  attachments: any[];
  user: string;
  userName: string;
  groupId: number;
  groupName: string;
  groupClientHref: string;
  assignee?: string;
  category?: string[];
  priority?: string;
  product?: string;
  files?: any;
  email?: string;
  status?: string;
  type?: string;
  datetimeSent?: any;
}

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  constructor(private afs: AngularFirestore) {}

  createSupportTicket(ticket: NewSupportTicket) {
    return this.afs.collection(`support-tickets`).add({
      status: 'open',
      type: SUPPORT_TICKET_TYPE.SERVICE,
      datetimeSent: firebase.firestore.Timestamp.now(),
      ...ticket,
    });
  }

  getTicketsFromFirebase(groupId: number, seeAllTickets: boolean = false) {
    let callback = this.afs.collection(`support-tickets`, (ref) =>
      ref
        .orderBy('datetimeSent', OrderDirection.Desc)
        .where('groupId', '==', groupId)
    );

    if (seeAllTickets) {
      callback = this.afs.collection(`support-tickets`, (ref) =>
        ref.orderBy('datetimeSent', OrderDirection.Desc)
      );
    }

    return callback.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action) => {
          const data = action.payload.doc.data() as any;
          const id = action.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
}
